import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Wrapper } from './styles'
import { useNavigate } from 'react-router-dom'
import Button from 'src/global/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addToOrderIntent, clearOrderIntent } from 'src/state/business-orders'
import { faArrowRight, faInfoCircle, faQrcode, faWifi } from '@fortawesome/free-solid-svg-icons'
import { State } from 'src/state/index'
import BusinessOrderSummary from './BusinessOrderSummary'
import { computeIntentSummary, HolderCode } from '@meniudigital/shared'
import { Screen } from 'src/screens'
import Holder from './Holder'
import Toggle from 'react-toggle'

const DEFAULT_PLEXIGLASS_VALUE = 10
const DEFAULT_LAMINATED_VALUE = 0
const DEFAULT_STICKER_VALUE = 0

export default function ChooseHolders() {
  const { user } = useSelector((state: State) => state.users)
  const { orderIntent = {} } = useSelector((state: State) => state.businessOrders)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearOrderIntent())
  }, [])

  const plexiglassStock = +(process.env.REACT_APP_PLEXIGLASS_STOCK || 0)
  const laminatedStock = +(process.env.REACT_APP_LAMINATED_STOCK || 0)
  const stickerStock = +(process.env.REACT_APP_STICKER_STOCK || 0)

  const [plexiglassHolderCount, setPlexiglassHolderCount] = useState(
    orderIntent.plexiglassHolderCount ?? Math.min(DEFAULT_PLEXIGLASS_VALUE, plexiglassStock)
  )
  const [laminatedHolderCount, setLaminatedHolderCount] = useState(
    orderIntent.laminatedHolderCount ?? Math.min(DEFAULT_LAMINATED_VALUE, laminatedStock)
  )
  const [stickerHolderCount, setStickerHolderCount] = useState(
    orderIntent.stickerHolderCount ?? Math.min(DEFAULT_STICKER_VALUE, stickerStock)
  )
  const [withWifiInfo, setWithWifiInfo] = useState(user?.wifiInfo?.isVisible)

  const hasNotChangedAnything =
    plexiglassHolderCount === DEFAULT_PLEXIGLASS_VALUE &&
    laminatedHolderCount === DEFAULT_LAMINATED_VALUE &&
    stickerHolderCount === DEFAULT_STICKER_VALUE

  const { totalPrice, holderPrice } = computeIntentSummary({
    ...orderIntent,
    plexiglassHolderCount,
    laminatedHolderCount,
    stickerHolderCount,
  })

  const hasWifiInfo = Boolean(user?.wifiInfo?.networkName?.length)

  const goToNextPage = () => {
    dispatch(
      addToOrderIntent({
        plexiglassHolderCount,
        laminatedHolderCount,
        stickerHolderCount,
        withWifiInfo: showsWifiOption && hasWifiInfo && withWifiInfo,
      })
    )

    if (plexiglassHolderCount + laminatedHolderCount + stickerHolderCount > 0) {
      navigate(Screen.EnterShippingInfo)
    } else {
      giveUp()
    }
  }

  const giveUp = () => {
    dispatch(clearOrderIntent())
    navigate(Screen.Home)
  }

  const showsWifiOption = plexiglassHolderCount > 0 || laminatedHolderCount > 0

  return (
    <Wrapper>
      <div className="page">
        <h3>Ce tip de holdere personalizate dorești pentru codul tău QR?</h3>
        <div className="row wrap">
          <Holder
            code={HolderCode.Sticker}
            count={stickerHolderCount}
            setCount={setStickerHolderCount}
            stock={stickerStock}
            images={[
              {
                url: '/holders/stickers/1.jpeg',
                marginLeft: -25,
              },
              {
                url: '/holders/stickers/2.jpeg',
                isLandscape: true,
                marginLeft: -45,
              },
              {
                url: '/holders/stickers/3.jpeg',
              },
            ]}
          />
          <Holder
            code={HolderCode.Laminated}
            count={laminatedHolderCount}
            setCount={setLaminatedHolderCount}
            stock={laminatedStock}
            images={[
              {
                url: '/holders/laminated/1.jpeg',
              },
              {
                url: '/holders/laminated/2.jpeg',
                marginLeft: -45,
                isLandscape: true,
              },
              {
                url: '/holders/laminated/3.jpeg',
              },
              {
                url: '/holders/laminated/4.jpeg',
              },
            ]}
          />
          <Holder
            code={HolderCode.Plexiglass}
            count={plexiglassHolderCount}
            setCount={setPlexiglassHolderCount}
            stock={plexiglassStock}
            images={[
              {
                url: '/holders/plexiglass/1.jpeg',
              },
              {
                url: '/holders/plexiglass/4.jpeg',
                isLandscape: true,
              },
              {
                url: '/holders/plexiglass/3.jpeg',
              },
              {
                url: '/holders/plexiglass/2.jpeg',
                isLandscape: true,
                marginLeft: -35,
              },
            ]}
          />
        </div>

        {/* <a href="https://www.etsy.com/market/qr_code_holder" target="_blank" rel="noreferrer">
          <i className="fa fa-external-link-alt" style={{ marginRight: 4, marginBottom: 24 }}></i>
          Vezi alte tipuri de holdere
        </a> */}

        {holderPrice > 0 && (
          <p className="info">
            <FontAwesomeIcon icon={faInfoCircle} className="icon" />
            Holderele vor fi personalizate cu logo-ul pe care l-ai încărcat în secțiunea "Logo & Cover".
          </p>
        )}

        {showsWifiOption && (
          <div className="panel wifi-row" style={{ fontWeight: hasWifiInfo ? 500 : 400 }}>
            <div>
              <FontAwesomeIcon icon={faWifi} className="icon" style={!withWifiInfo ? { color: '#b0b8bf' } : {}} />
              {hasWifiInfo ? (
                <span>Afișează rețeaua și parola WiFi pe holdere</span>
              ) : (
                <span>
                  Dorești afișarea rețelei/parolei WiFi pe holderele comandate? Adaugă-le în setările meniului și revino pe acestă
                  pagină.
                </span>
              )}
            </div>
            {hasWifiInfo && (
              <div className="react-toggle-wrapper">
                <Toggle
                  checked={withWifiInfo}
                  title="Ascunde categoria"
                  icons={false}
                  onChange={() => setWithWifiInfo(!withWifiInfo)}
                />
              </div>
            )}
          </div>
        )}

        {holderPrice === 0 && (
          <div className="panel prefer-no-holders">
            <span>
              Preferi să folosești holderele proprii pentru codul QR? Poți obține oricând codul în format vectorial accesând{' '}
              <span className="button-like" onClick={() => window.openMyQrCodeModal()}>
                Codul meu QR <FontAwesomeIcon className="icon" icon={faQrcode} />
              </span>
              din meniul din dreapta-sus.
            </span>
            <Button variant="as-text" text="Continuă fără holdere" onClick={goToNextPage} />
          </div>
        )}

        <BusinessOrderSummary
          order={{
            plexiglassHolderCount,
            laminatedHolderCount,
            stickerHolderCount,
          }}
        />

        {holderPrice > 0 && (
          <>
            <Button
              className="continue-button"
              text="Continuă"
              disabled={totalPrice <= 0}
              onClick={goToNextPage}
              icon={faArrowRight}
            />
            {hasNotChangedAnything && (
              <Button style={{ marginTop: 8 }} variant="as-text" text="Continuă fără holdere" onClick={giveUp} />
            )}
          </>
        )}
      </div>
    </Wrapper>
  )
}
