import styled from 'styled-components/macro'
// @ts-ignore
import Toggle from 'react-toggle'

export const Backdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 57px;
  left: 0;
  z-index: 99;
  background: ${props => props.theme.backdrop};
  transition: opacity 0.25s ease-in-out;

  &.closed {
    pointer-events: none;
    opacity: 0;
  }
`

export const Modal = styled.div`
  position: fixed;
  z-index: 100;
  width: 780px;
  top: 10vh;
  left: 0;
  right: 0;
  margin: auto;
  background: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.border};
  transition: opacity 0.25s ease-in-out;
  color: ${props => props.theme.normalText};

  border-radius: 8px;
  box-shadow: 0 4px 42px ${props => props.theme.shadow};
  overflow: hidden;

  .ios-toggle {
    margin-top: 8px;
    width: calc(((780px - (16px * 3)) / 2) - 2px - 8px);

    @media (max-width: 768px) {
      width: 100%;
    }

    background: ${props => props.theme.pageBackground};
  }

  &.teal {
    button:not([data-variant='as-text']):not([data-variant='secondary']) {
      background: ${props => props.theme.teal};
    }

    [data-variant='as-text'] {
      color: ${props => props.theme.teal};
    }
  }

  &.closed {
    pointer-events: none;
    opacity: 0;
  }

  @media (max-width: 768px) {
    width: min(1024px, calc(100vw - 32px)) !important;
    top: 77px;
  }

  p.info {
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 12px;
    padding: 16px 5vw 0 5vw;
    text-align: center;

    .icon {
      margin-right: 5px;
      color: ${props => props.theme.accent};
    }
  }

  .product-variants {
    width: 100%;
  }

  .product-variant {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    position: relative;
    width: 100%;
    margin: 8px 0;
    border: 1px solid ${props => props.theme.border}88;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    column-gap: 10px;

    &.static {
      margin-top: 0;
      border: none;
      box-shadow: none;
      padding: 0;
    }

    .remove-button {
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 16px;
      cursor: pointer;

      :hover {
        color: ${props => props.theme.red};
      }

      @media (max-width: 768px) {
        /* top: 16px;
        right: 16px;
        bottom: auto; */
      }
    }

    &:nth-of-type(even) {
      /* background: ${props => props.theme.accent}22; */
    }

    &:hover {
      background: #11b7f311;
    }
  }

  .plus-button {
    background: ${props => props.theme.white};
    height: 35px;
    width: 35px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 1px 8px #818e9988;
    transition: width 0.3s cubic-bezier(0.25, 1, 0.32, 1);
    position: absolute;
    z-index: 1000;
    right: 8px;
    bottom: 0;
    top: 0;
    margin: auto;
    transition: width 0.5s cubic-bezier(0.25, 1, 0.32, 1), border-radius 1s cubic-bezier(0.25, 1, 0.32, 1) 0.3s;

    @media (max-width: 768px) {
      top: 40px;
    }

    span {
      color: ${props => props.theme.white};
      margin-right: 16px;
      display: none;
      font-weight: 500;
      font-size: 16px;
      white-space: nowrap;
    }

    svg {
      font-size: 20px;
      color: ${props => props.theme.accent};
      padding: 0 4px;
    }

    :hover {
      transition: width 0.5s cubic-bezier(0.25, 1, 0.32, 1) 0.05s, border-radius 0.1s cubic-bezier(0.25, 1, 0.32, 1);
      background: ${props => props.theme.accent};

      svg {
        color: ${props => props.theme.white};
      }
    }

    :not(.in-list):hover {
      width: auto;
      border-radius: 8px;
      width: 260px;

      span {
        display: inline;
      }
    }
  }

  .add-variant-row {
    width: 100%;
    display: flex;
    justify-content: center;

    .plus-button {
      position: static;
      background: ${props => props.theme.accent};
      width: auto;
      border-radius: 8px;
      transition: box-shadow 0.15s ease-out;

      &:hover {
        width: auto;
        box-shadow: 0 4px 12px ${props => props.theme.mutedText};
      }

      span {
        display: inline;
      }

      .fa {
        color: ${props => props.theme.white};
      }
    }
  }

  .magic-button {
    width: 132px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    height: 100%;
    margin-right: -6px;
    background: linear-gradient(20deg, hsl(206deg 100% 50%), hsl(192deg 98% 52%));
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.25, 1, 0.32, 1);
    position: absolute;
    right: 10px;
    bottom: 4px;
    height: 40px;
    z-index: 1001;
    color: ${props => props.theme.white};
    font-weight: 600;

    @media (max-width: 768px) {
      width: 75px;

      span {
        display: none;
      }
    }

    img {
      transition: all 0.5s cubic-bezier(0.25, 1, 0.32, 1);
      width: 26px;
    }

    &:hover {
      filter: drop-shadow(2px 0px 4px #00000022);

      img {
        transform: scale(1.18) rotate(13deg);
      }
    }

    &.loading {
      opacity: 0.6;
      pointer-events: none;
      filter: none;

      img {
        animation: rotate 2.5s linear infinite;
      }
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }

    @media (max-width: 768px) {
      width: 50px;
    }
  }

  .disclaimer {
    font-size: 14px;
    font-style: italic;
    color: ${props => props.theme.mutedText};
    font-weight: 500;
    text-align: right;
    width: 100%;

    i {
      margin-right: 4px;
    }
  }

  .allergen-list {
    display: flex;
    flex-wrap: wrap;

    .allergen {
      padding: 4px 8px;
      border-radius: 16px;
      background: ${props => props.theme.white};
      border: 1px solid ${props => props.theme.border};
      margin-top: 8px;
      margin-right: 8px;
      cursor: pointer;
      font-size: 15px;

      @media (hover) {
        :hover {
          border-color: ${props => props.theme.orange};
          color: ${props => props.theme.orange};
        }
      }

      &.selected {
        color: ${props => props.theme.orange};
        background: ${props => props.theme.orange}0f;
        border-color: ${props => props.theme.orange}aa;
        font-weight: 500;
        border-width: 2px;
      }
    }
  }

  .product-properties {
    display: flex;
    flex-wrap: wrap;

    .product-property {
      padding: 4px 10px;
      border-radius: 16px;
      background: ${props => props.theme.white};
      border: 1px solid ${props => props.theme.border};
      margin-top: 8px;
      margin-right: 8px;
      cursor: pointer;
      font-size: 15px;
      display: flex;
      align-items: center;

      img {
        height: 14px;
        margin-right: 6px;
        filter: saturate(0);
      }

      @media (hover) {
        :hover {
          &.orange {
            color: ${props => props.theme.orange};
            border-color: ${props => props.theme.orange};
          }
          &.green {
            color: ${props => props.theme.green};
            border-color: ${props => props.theme.green};
          }
          &.red {
            color: ${props => props.theme.red};
            border-color: ${props => props.theme.red};
          }
          &.blue {
            color: ${props => props.theme.blue};
            border-color: ${props => props.theme.blue};
          }

          img {
            filter: saturate(1);
          }
        }
      }

      &.selected {
        &.red {
          color: ${props => props.theme.red};
          background: ${props => props.theme.red}11;
          border-color: ${props => props.theme.red};
        }
        &.blue {
          color: ${props => props.theme.blue};
          background: ${props => props.theme.blue}11;
          border-color: ${props => props.theme.blue};
        }

        &.orange {
          color: ${props => props.theme.orange};
          background: ${props => props.theme.orange}11;
          border-color: ${props => props.theme.orange};
        }
        &.green {
          color: ${props => props.theme.green};
          background: ${props => props.theme.green}11;
          border-color: ${props => props.theme.green};
        }

        img {
          filter: saturate(1);
        }

        font-weight: 500;
        border-width: 2px;
      }
    }
  }
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 24px 16px;
  padding-right: 45px;

  width: 100%;
  background: ${props => props.theme.pageBackground};
`

export const CloseButtonWrapper = styled.div`
  cursor: pointer;
  margin-top: -12px;
  position: absolute;
  right: 17px;
  top: 25px;
  transform: rotate(45deg);

  width: 50px;
  height: 50px;

  margin-right: -11px;

  :hover {
    opacity: 0.6;
  }
`

export const CloseButtonLineOne = styled.div`
  width: 22px;
  height: 2px;
  background: #bbc3c8;
  display: inline-block;
  position: relative;
  top: 0px;
  left: 11px;
`

export const CloseButtonLineTwo = styled.div`
  height: 22px;
  width: 2px;
  background: #bbc3c8;
  display: inline-block;
  margin-left: -22.5px;
  position: relative;
  top: 10px;
  left: 21px;
`

export const ModalContent = styled.div`
  padding: 16px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  transition: all 0.3s ease-in-out;
  max-height: calc(100vh - 285px);
  overflow: auto;
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    max-height: 55vh;
    padding-top: 0;
  }

  .image-dropper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 16px;

    p {
      text-align: center;
      max-width: 60vw;
      font-weight: 400;
    }
  }

  .image-icon-wrapper {
    position: absolute;
    left: 12px;
    top: 8px;
    z-index: 15;
    pointer-events: none;
  }

  .toggles-wrapper {
    margin: 16px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
  }

  .toggle-wrapper {
    display: flex;
    align-items: center;
    align-items: flex-end;
    padding-left: 0;

    @media (max-width: 768px) {
      width: 100%;
    }

    span {
      padding-left: 8px;
      padding-bottom: 2px;
      white-space: nowrap;
    }
  }
`

export const ModalFooter = styled.div`
  padding: 16px;
  background: ${props => props.theme.pageBackground};
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    margin-top: 0;
  }
`

export const AddButton = styled.div`
  display: inline-block;
  color: ${props => props.theme.accent};
  cursor: pointer;
  margin: 0 8px;

  &:hover {
    opacity: 0.6;
  }
`

export const RemoveButton = styled.div`
  display: inline-block;
  color: ${props => props.theme.red};
  cursor: pointer;
  margin: 0 8px;

  &:hover {
    opacity: 0.6;
  }
`

export const FieldButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  padding-top: 4px;
`

export const ProductPropertyToggle = styled(Toggle)`
  &.react-toggle--focus .react-toggle-thumb {
    box-shadow: none !important;
  }

  &.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${props => props.theme.mutedText}44;
  }

  &.react-toggle .react-toggle-track {
    background-color: ${props => props.theme.mutedText}88;
  }

  &.react-toggle:not(.react-toggle--checked) .react-toggle-thumb {
    border-color: ${props => props.theme.mutedText}88;
  }

  &.react-toggle--checked .react-toggle-thumb {
    border-color: ${props => props.theme.accent};
  }

  &.react-toggle--checked .react-toggle-track {
    background-color: ${props => props.theme.accent};
  }

  &.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${props => props.theme.accent}bb;
  }
`

export const DropArea = styled.div`
  background-color: #eceff144;
  border: 1px solid ${props => props.theme.border};
  border-radius: 8px;
  width: 100%;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  color: ${props => props.theme.normalText};

  &.dragged {
    background: ${props => props.theme.accent}10;
    border: 1px solid ${props => props.theme.accent};
    color: ${props => props.theme.accent};

    > div {
      color: ${props => props.theme.accent};
    }
  }
`

export const ProductImageWrapper = styled.div`
  border-radius: 8px;
  height: 26vh;
  overflow: hidden;
  position: relative;
  justify-content: flex-end;
  display: inline-flex;
  flex-direction: column;
  margin: auto;

  img {
    width: auto;
    height: 100%;
  }
`
export const ImageActionButton = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  color: white;
  border-radius: 8px;
  font-size: 12px;
  box-shadow: 0 4px 12px ${props => props.theme.backdrop};
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;

  text-shadow: 0 4px 12px #fff;
  padding: 5px 9px;
  background: ${props => props.theme.accent};
  font-size: 14px;

  :first-letter {
    text-transform: uppercase;
  }

  &.danger {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: ${props => props.theme.red};
    right: auto;
    left: 0;
  }

  :hover {
    filter: brightness(110%);
    transition: 300ms;
  }
`

export const ErrorText = styled.div`
  margin-top: 8px;
  color: ${props => props.theme.red};
  font-size: 14px;
  font-weight: 500;
`

export const UploadImageBackground = styled.img`
  width: 100%;
  position: absolute;
  left: 0px;
  opacity: 0.15;
  z-index: -1;
  min-height: 100%;
`

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  position: relative;

  @media (max-width: 768px) {
    width: 100% !important;
    margin-left: 0px !important;
  }
`

export const Flag = styled.div`
  position: absolute;
  bottom: 8px;
  right: 13px;
`

export const QuantityAndStock = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const IsInStockToggle = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 8px 16px;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 16px;
  }
`

export const HiddenMobile = styled.span`
  @media (max-width: 1024px) {
    display: none;
  }
`
