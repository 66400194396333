import React, { useEffect, useRef, useState } from 'react'
import Dropzone from 'react-dropzone'
import {
  Backdrop,
  Modal,
  Header,
  ModalContent,
  ModalFooter,
  ProductPropertyToggle,
  DropArea,
  ProductImageWrapper,
  ImageActionButton,
  UploadImageBackground,
  HiddenMobile,
} from './styles'
import { faCheck, faClone, faImage, faPencilAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'src/global/Button'
import Flag from 'src/global/Flag'
import CloseButton from 'src/global/CloseButton'
import { useDispatch, useSelector } from 'react-redux'
import { createProduct, editProduct } from 'src/state/products'
import {
  MenuProduct,
  LanguageCode,
  RomanianLanguageName,
  CurrencyCode,
  ProductProperty,
  ALLERGENS,
  ALL_PRODUCT_PROPERTIES,
} from '@meniudigital/shared'
import FormField from 'src/global/form/FormField'
import { categoriesSelector } from 'src/state/selectors'
import { State } from 'src/state'
import { IS_DESKTOP_OR_TABLET } from '..'
import compressImage from 'src/helpers/compressImage'
import getImageBase64 from 'src/helpers/getImageBase64'
import { EditMode, SelectedFileProperties, SelectedImage, SelectedImageProperties } from './types'
import { generateEmptyProduct, hasWhiteBackground } from './helpers'
import { productEndpoints, userEndpoints } from 'src/api'
import ImageSuggestionBox from './ImageSuggestionBox'
import getGenericImageUrl from 'src/helpers/getGenericImageUrl'
import { extractKcal } from 'src/helpers/extractKcal'

const MAX_PRODUCT_VARIANTS = 6

export default function ProductModal({ languageCode }: { languageCode: LanguageCode }) {
  const dispatch = useDispatch()
  const fileUploadInputRef = useRef<HTMLInputElement>(null)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [mode, setMode] = useState<EditMode | undefined>()
  const [parentProduct, setProduct] = useState<MenuProduct & { genericImageName?: string }>({ ...generateEmptyProduct() })
  const [selectedImage, setSelectedImage] = useState<SelectedImage | undefined>(undefined)
  const [selectedImageProperties, setSelectedImageProperties] = useState<SelectedImageProperties>()
  const [selectedFileProperties, setSelectedFileProperties] = useState<SelectedFileProperties>()
  const displayedImageRef = useRef<HTMLImageElement>(null)
  const [isComputing, setIsComputing] = useState<boolean>(false)

  const { user } = useSelector((state: State) => state.users)

  useEffect(() => {
    window.openProductModal = open
  }, [])

  const targetLanguageCode = (
    mode === EditMode.Edit && languageCode !== LanguageCode.Romanian ? languageCode : LanguageCode.Romanian
  ) as LanguageCode

  const open = (productOrCategoryId: string | object, isClone?: boolean) => {
    if (typeof productOrCategoryId === 'object') {
      if (isClone) {
        const productToClone = JSON.parse(JSON.stringify(productOrCategoryId)) as MenuProduct
        productToClone.id = ''
        productToClone.childProducts?.forEach(x => {
          x.id = Math.random() * 100 + 'randomIdBeforeCreation'
        })
        setMode(EditMode.Clone)
        setProduct({ ...productToClone })
      } else {
        const productToEdit = productOrCategoryId as MenuProduct
        setMode(EditMode.Edit)
        setProduct({ ...productToEdit })
      }
    } else {
      const categoryId = productOrCategoryId as string

      setMode(EditMode.Create)
      setProduct(generateEmptyProduct({ categoryId }))
    }
    setSelectedImage(undefined)
    setIsComputing(false)
    setIsOpen(true)
    document.querySelector('body')!.style.overflowY = 'hidden'
  }

  const save = () => {
    if (!isProductValid(parentProduct)) {
      return
    }

    close()

    const productData = new FormData()

    if (selectedImage) {
      productData.append('imageFile', selectedImage.binary as Blob, selectedImage.name)
      parentProduct.imageInfo = {
        ...selectedFileProperties,
        ...selectedImageProperties,
      }
    }

    productData.append('productJson', JSON.stringify(parentProduct))

    setTimeout(() => {
      if (mode === EditMode.Edit) {
        dispatch(editProduct(productData))
      } else {
        dispatch(createProduct(productData))
      }
    }, 0)
  }

  const close = () => {
    setIsOpen(false)
    document.querySelector('body')!.style.overflowY = 'scroll'
  }

  const toggleAllergen = (allergen: string) => {
    let newAllergens = [...(parentProduct.allergens || [])]

    if (!newAllergens.includes(allergen)) {
      newAllergens.push(allergen)
    } else {
      newAllergens = newAllergens.filter((x: string) => x !== allergen)
    }

    setProduct({ ...parentProduct, allergens: newAllergens })
  }

  const toggleProperty = (property: ProductProperty) => {
    let newProperties = [...(parentProduct.properties || [])]

    if (!newProperties.includes(property)) {
      newProperties.push(property)
    } else {
      newProperties = newProperties.filter((x: string) => x !== property)
    }

    setProduct({ ...parentProduct, properties: newProperties })
  }

  const computeNutritionalDeclaration = async () => {
    if (parentProduct.names['ro'].length < 4 || parentProduct.descriptions['ro'].length < 4) {
      await window.showConfirm({
        title: `Completează detaliile produsului`,
        text: 'Pentru a genera declarația nutrițională, asigură-te că ai completat atât numele și descrierea produsului cât și lista de ingrediente, cu gramajele aferente acestora.',
      })
      return
    }

    setIsComputing(true)

    const response = await productEndpoints.computeNutritionalDeclaration(
      parentProduct.names['ro'],
      parentProduct.descriptions['ro']
    )

    if ((response as any).hasExceededFreeGenerationQuota) {
      setTimeout(() => {
        userEndpoints.registerUserAction({
          actionType: 'ClickOnExceededNutritionalDeclaration',
          data: { productId: parentProduct.id, name: parentProduct.names['ro'], description: parentProduct.descriptions['ro'] },
        })
      }, 0)
      window.openBusinessModal('autoNutritionalValues')
    } else {
      setTimeout(() => {
        userEndpoints.registerUserAction({
          actionType: 'GenerateDeclaration',
          data: { productId: parentProduct.id, name: parentProduct.names['ro'], description: parentProduct.descriptions['ro'] },
        })
      }, 0)

      setProduct({ ...parentProduct, nutritionalDeclaration: response, kcalories: extractKcal(response) })
    }

    setIsComputing(false)
  }

  const toggleDiscounted = () => {
    setProduct({ ...parentProduct, isDiscounted: !parentProduct.isDiscounted })
  }

  const toggleAvailable = () => {
    setProduct({ ...parentProduct, isAvailable: !parentProduct.isAvailable })
  }

  const toggleHidden = () => {
    setProduct({ ...parentProduct, isHidden: !parentProduct.isHidden })
  }

  const addProductVariant = () => {
    const commonProps = {
      quantities: parentProduct.quantities,
      kcalories: parentProduct.kcalories,
      price: parentProduct.price,
      categoryId: parentProduct.categoryId,
    }

    setProduct({
      ...parentProduct,
      childProducts: parentProduct.childProducts?.length
        ? [...parentProduct.childProducts, generateEmptyProduct({ index: parentProduct.childProducts.length, ...commonProps })]
        : [generateEmptyProduct({ index: 0, ...commonProps }), generateEmptyProduct({ index: 1, ...commonProps })],
    })
  }

  const setProductImage = async (files: FileList | any[]) => {
    const image = files[0]

    if (image) {
      const validExtensions = ['.png', '.jpg', '.jpeg']

      if (!validExtensions.find(extension => image.name.toLowerCase().includes(extension))) {
        alert('Te rog alege o imagine de tipul PNG sau JPG.')
        return
      }

      const compressedImage = await compressImage(image)
      const imageBase64 = await getImageBase64(compressedImage)

      setProduct({ ...parentProduct, imageUrl: '', imageKey: '', genericImageName: '' })
      setSelectedImage({ binary: compressedImage, base64: imageBase64, name: compressedImage.name || '' })
      setSelectedFileProperties({ uncompressedSizeInKb: image.size / 1000, sizeInKb: compressedImage.size / 1000 })
    }
  }

  const setGenericImageName = (imageName: string) => {
    setProduct({ ...parentProduct, imageUrl: '', imageKey: '', genericImageName: imageName })
    setSelectedImage(undefined)
  }

  const computeImageProperties = async () => {
    const { naturalWidth, naturalHeight } = displayedImageRef.current!

    setSelectedImageProperties({
      width: naturalWidth,
      height: naturalHeight,
      hasWhiteBackground: await hasWhiteBackground(displayedImageRef.current!),
      isGeneric: false,
    })
  }

  const removeProductImage = () => {
    setProduct({ ...parentProduct, imageUrl: '', imageKey: '', genericImageName: '' })
    setSelectedImage(undefined)
  }

  const changeProductImage = () => {
    if (fileUploadInputRef.current?.value) {
      fileUploadInputRef.current.value = ''
    }
    fileUploadInputRef.current?.click()
  }

  const isProductValid = (targetProduct: MenuProduct) => {
    const { names = {}, price, discountedPrice, childProducts } = targetProduct

    const requiredLanguageCode = mode === EditMode.Edit ? languageCode : LanguageCode.Romanian

    if ((childProducts || []).length) {
      // If has children, validate all of them
      if (childProducts?.some(x => !isProductValid(x))) {
        return false
      }
      // And also check if this parent product has a name
      if (!parentProduct.names[requiredLanguageCode]?.trim()?.length) {
        return false
      }
    } else {
      // @ts-ignore
      const isPriceWrong = (typeof price !== 'number' && !price?.length) || price === '.'

      // While creating (or cloning), we need to check if the name is empty for Romanian
      // While editing, we need to check if the name is empty for the current language
      if (!names[requiredLanguageCode]?.trim()?.length || isPriceWrong) {
        return false
      }

      if (parentProduct.isDiscounted && !discountedPrice) {
        return false
      }
    }

    return true
  }

  const onKeyDown = (e: React.KeyboardEvent) => {
    if ((e.target as HTMLTextAreaElement).tagName === 'TEXTAREA') {
      return
    }

    if (e.keyCode === 13) {
      save()
    }
    if (e.keyCode === 27) {
      close()
    }
  }

  const { list: categories } = useSelector(categoriesSelector)

  const withProduct = () => ({ product: parentProduct, setProduct })

  const setChildProduct = (newChildProduct: MenuProduct) => {
    const newChildProducts = [...(parentProduct.childProducts || []).filter(x => x.id !== newChildProduct.id), newChildProduct]
    setProduct({ ...parentProduct, childProducts: newChildProducts })
  }

  const removeChildProduct = (childProductId: string) => {
    const newChildProducts = [...(parentProduct.childProducts || []).filter(x => x.id !== childProductId)]
    setProduct({ ...parentProduct, childProducts: newChildProducts })
  }

  const renderProductVariant = (childProduct: MenuProduct, i?: number, list?: MenuProduct[]) => {
    const withChildProduct = () => {
      if (!list) {
        return withProduct()
      }

      return {
        product: childProduct,
        setProduct: setChildProduct,
      }
    }

    return (
      <div key={childProduct.id} className={'product-variant' + (!list ? ' static' : '')}>
        {list && (
          <>
            <FormField
              style={{ width: '250px' }}
              for={(targetProduct: MenuProduct) => targetProduct.names[targetLanguageCode]}
              setProperty={newValue =>
                list
                  ? setChildProduct({ ...childProduct, names: { ...childProduct.names, [targetLanguageCode]: newValue } })
                  : setProduct({ ...childProduct, names: { ...childProduct.names, [targetLanguageCode]: newValue } })
              }
              required
              label={`Nume variantă`}
              placeholder="e.g. Mică/Medie/Family"
              suffix={<Flag of={targetLanguageCode} />}
              maxLength={40}
              product={childProduct}
            />
          </>
        )}
        <FormField
          style={{ width: '135px' }}
          for="quantities"
          label="Gramaj"
          maxLength={25}
          placeholder="e.g. 200g/50ml"
          {...withChildProduct()}
        />
        <FormField
          invalid={Boolean(childProduct.kcalories?.match(/[A-Za-z]/))}
          for="kcalories"
          label="Kcal (per 100g)"
          asNumber
          max={9999}
          suffix="kcal"
          {...withChildProduct()}
        />
        <FormField
          required
          for="price"
          label={childProduct.isDiscounted ? 'Preț inițial' : 'Preț'}
          asNumber
          max={9999}
          suffix={(user?.defaultCurrency || CurrencyCode.Ron).toUpperCase()}
          {...withChildProduct()}
        />
        {parentProduct.isDiscounted && (
          <FormField
            for="discountedPrice"
            required
            label="Preț redus"
            asNumber
            max={9999}
            suffix={(user?.defaultCurrency || CurrencyCode.Ron).toUpperCase()}
            {...withChildProduct()}
          />
        )}

        {user?.isUsingPartyMode && (
          <FormField
            for="priceDuringEvent"
            label="Preț eveniment"
            asNumber
            placeholder={typeof childProduct.price !== 'undefined' ? `${childProduct.price}` : ''}
            max={9999}
            suffix={(user?.defaultCurrency || CurrencyCode.Ron).toUpperCase()}
            {...withChildProduct()}
          />
        )}

        {list ? (
          <div className="remove-button" onClick={() => removeChildProduct(childProduct.id)} title="Șterge varianta de preț">
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ) : (
          <div onClick={addProductVariant} className={`plus-button`} title="Adaugă o variantă de preț">
            <FontAwesomeIcon icon={faPlus} />
            <span>Adaugă variantă de preț</span>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <Backdrop className={isOpen ? 'open' : 'closed'} />
      <Modal onKeyDown={onKeyDown} className={(isOpen ? 'open' : 'closed') + ' ' + (mode === EditMode.Clone ? 'teal' : '')}>
        <Header>
          {mode === EditMode.Edit ? (
            <span>
              <FontAwesomeIcon style={{ marginRight: 4, color: '#11b7f3' }} icon={faPencilAlt} />
              Editează produsul{IS_DESKTOP_OR_TABLET ? <b> "{parentProduct.names['ro']}"</b> : null}
            </span>
          ) : mode === EditMode.Clone ? (
            <span>
              <FontAwesomeIcon style={{ marginRight: 4, color: '#32bd98' }} icon={faClone} />
              Duplică produsul{IS_DESKTOP_OR_TABLET ? <b> "{parentProduct.names['ro']}"</b> : null}
            </span>
          ) : (
            <span>
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: 6, color: '#11b7f3' }} />
              Adaugă produs în "{categories.find(x => x.id === parentProduct.categoryId)?.names['ro'] || ''}"
            </span>
          )}
          <CloseButton onClick={close} title="Închide" />
        </Header>
        <ModalContent>
          <div className="image-dropper">
            {!parentProduct.imageUrl && !selectedImage?.base64 && !parentProduct.genericImageName ? (
              <div style={{ paddingTop: '12px', marginBottom: '8px', width: '100%' }}>
                <Dropzone accept={['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml']} onDrop={setProductImage}>
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <DropArea {...getRootProps()} className={isDragActive ? 'dragged' : ''}>
                      <div className="image-icon-wrapper">
                        <FontAwesomeIcon icon={faImage} />
                      </div>
                      <input accept="image/jpeg,image/png,image/gif" {...getInputProps({ multiple: false })} />
                      <p>Trage o poză aici sau fă click pentru a alege manual.</p>
                      <UploadImageBackground alt="Incarcă o poză" src="/misc/upload-image-bg-min.jpeg" />
                    </DropArea>
                  )}
                </Dropzone>
                <ImageSuggestionBox query={parentProduct.names[LanguageCode.Romanian]} onSelectPress={setGenericImageName} />
              </div>
            ) : (
              <ProductImageWrapper>
                <div style={{ height: 0, width: 0, overflow: 'hidden' }}>
                  <input
                    ref={fileUploadInputRef}
                    type="file"
                    accept="image/jpeg,image/png,image/gif"
                    onChange={() => setProductImage(fileUploadInputRef?.current?.files!)}
                  />
                </div>
                <img
                  onLoad={computeImageProperties}
                  ref={displayedImageRef}
                  alt="Poză produs"
                  src={parentProduct.imageUrl || selectedImage?.base64 || getGenericImageUrl(parentProduct.genericImageName)}
                />
                <ImageActionButton className="danger" onClick={removeProductImage}>
                  Șterge <HiddenMobile> poza</HiddenMobile>
                </ImageActionButton>
                <ImageActionButton onClick={changeProductImage}>
                  <HiddenMobile>Alege </HiddenMobile>alta...
                </ImageActionButton>
              </ProductImageWrapper>
            )}
          </div>

          <FormField
            for={(product: MenuProduct) => product.names[targetLanguageCode]}
            setProperty={newValue =>
              setProduct({ ...parentProduct, names: { ...parentProduct.names, [targetLanguageCode]: newValue } })
            }
            required
            fullWidth
            label={`Nume (în limba ${RomanianLanguageName[targetLanguageCode]})`}
            placeholder="Nume produs"
            maxLength={150}
            suffix={<Flag of={targetLanguageCode} />}
            {...withProduct()}
          />

          <FormField
            for={(product: MenuProduct) => product.descriptions[targetLanguageCode]}
            setProperty={newValue =>
              setProduct({
                ...parentProduct,
                descriptions: { ...parentProduct.descriptions, [targetLanguageCode]: newValue },
              })
            }
            label={`Descriere produs finit (în limba ${RomanianLanguageName[targetLanguageCode]})`}
            placeholder="e.g. 150g piept de pui dezosat, 150g ciuperci, 150g smântănâ (descriere produs finit, așa cum va fi servit de către client)"
            maxLength={1500}
            multiline={true}
            fullWidth
            suffix={<Flag of={targetLanguageCode} />}
            {...withProduct()}
          />
          <div className="product-variants">
            {(parentProduct.childProducts || []).length > 0
              ? [...parentProduct.childProducts!].sort((a, b) => (a.index > b.index ? 1 : -1))!.map(renderProductVariant)
              : renderProductVariant(parentProduct)}
          </div>
          <div className="add-variant-row">
            {(parentProduct.childProducts || []).length > 0 &&
              (parentProduct.childProducts || []).length < MAX_PRODUCT_VARIANTS && (
                <Button variant="secondary" onClick={addProductVariant} text="Adaugă variantă de preț" icon={faPlus} />
              )}
          </div>
          <div className="toggles-wrapper">
            <div className="toggle-wrapper">
              <ProductPropertyToggle
                checked={parentProduct.isAvailable}
                title="În stoc"
                icons={false}
                onChange={toggleAvailable}
              />
              <span>{parentProduct.isAvailable ? 'În stoc' : 'Fără stoc'}</span>
            </div>
            <div className="toggle-wrapper">
              <ProductPropertyToggle
                checked={!parentProduct.isHidden}
                title="Ascuns din meniu?"
                icons={false}
                onChange={toggleHidden}
              />
              <span>{parentProduct.isHidden ? 'Ascuns din meniu' : 'Vizibil'}</span>
            </div>
            <div className="toggle-wrapper">
              <ProductPropertyToggle
                checked={parentProduct.isDiscounted}
                title="La reducere?"
                icons={false}
                onChange={toggleDiscounted}
              />
              <span>Redus</span>
            </div>
          </div>

          <FormField
            for="nutritionalDeclaration"
            fullWidth
            multiline
            label={`Declarație nutrițională (per 100g)`}
            disabled={isComputing}
            placeholder={
              isComputing
                ? 'Se generează...'
                : 'e.g. Lipide 126g din care acizi grași saturați 79g, zaharuri 8g, proteine 182g, sare 9g'
            }
            maxLength={500}
            rightButton={
              <div
                className={`magic-button ${isComputing ? 'loading' : ''}`}
                title="Generează declarația nutrițională"
                onClick={computeNutritionalDeclaration}
              >
                <img src="/chat-gpt.svg" />
                <span>Generează</span>
              </div>
            }
            {...withProduct()}
          />

          <span className="disclaimer">
            Autogenerarea poate crea uneori informații eronate.{' '}
            <a
              href="https://www.meniudigital.ro/blog/calcul-nutritional-inteligenta-artificiala"
              target="_blank"
              rel="noreferrer"
            >
              Află mai multe
            </a>
          </span>

          <div style={{ marginTop: 24 }}>
            <label>Alergeni</label>
            <div className="allergen-list">
              {ALLERGENS.map(x => (
                <div
                  key={x.id}
                  className={'allergen ' + ((parentProduct.allergens || []).includes(x.id) ? 'selected' : '')}
                  onClick={() => toggleAllergen(x.id)}
                >
                  <div className="short-name"> {x.shortNames['ro']}</div>
                </div>
              ))}
            </div>
          </div>

          <div style={{ marginTop: 24 }}>
            <label>Alte proprietăți produs</label>
            <div className="product-properties">
              {ALL_PRODUCT_PROPERTIES.map(x => (
                <div
                  key={x.type}
                  className={`product-property ${x.color} ${(parentProduct.properties || []).includes(x.type) ? 'selected' : ''}`}
                  onClick={() => toggleProperty(x.type)}
                >
                  <img alt={x.name} src={`properties/${x.iconName}.png`} />
                  <div className="short-name">{x.name}</div>
                </div>
              ))}
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button variant="as-text" onClick={close} text="Anulează" />
          <Button
            onClick={save}
            disabled={!isProductValid(parentProduct)}
            text={mode === EditMode.Edit ? 'Salvează' : 'Adaugă'}
            icon={mode === EditMode.Edit ? faCheck : faPlus}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}
