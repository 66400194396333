import React, { useEffect, useState } from 'react'
import { faArrowRight, faEnvelope, faPlus } from '@fortawesome/free-solid-svg-icons'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import * as paymentPendingAnimation from 'src/assets/animations/payment-pending.json'

import { Wrapper, Panel, Button as ButtonStyle, Footer } from './styles'

import ProductModal from './ProductModal'
import CategoryModal, { SECTION_OPTIONS } from './CategoryModal/index'
import Category from './Category'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { getProducts, moveProduct, removeProduct as removeProductAction, transferProduct } from 'src/state/products'
import { getCategories, moveCategory, removeCategory as removeCategoryAction } from 'src/state/categories'
import { clearOrderIntent, getBusinessOrders } from 'src/state/business-orders'
import {
  MenuCategory,
  MenuProduct,
  BusinessOrderStatus,
  HolderCode,
  LanguageCode,
  MenuSection,
  getDaysLeft,
  isBusiness,
} from '@meniudigital/shared'
import CloseButton from 'src/global/CloseButton'
import { Screen } from 'src/screens'
import Lottie from 'lottie-react'
import Button from 'src/global/Button'
import OrderSummary from '../BusinessOrders/ChooseHolders/BusinessOrderSummary'
import CategoryInfoToast from './CategoryInfoToast'
import InfoPanel from 'src/global/InfoPanel'
import { categoriesSelector, productsSelector } from 'src/state/selectors'
import { getCurrentUser, updateCurrentUser } from 'src/state/users'
import StatsPanel from './StatsPanel'
import { userEndpoints } from 'src/api'
import MenuPreview from './MenuPreview'
import MenuActionsRow from './MenuActionsRow'
import PrintMenuModal from './PrintMenuModal'
import SectionPicker from './SectionPicker'
import StockReminderToast from './StockReminderToast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const IS_DESKTOP_OR_TABLET = window.innerWidth > 1024
export const firstOfSeptember = 1661990400000

export default function Home() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [query, setQuery] = useState('')
  const [inJiggleMode, setInJiggleMode] = useState(false)
  const [languageCode, setLanguageCode] = useState<LanguageCode>(LanguageCode.Romanian)
  const [viewType, setViewType] = useState<MenuSection>(MenuSection.Restaurant)
  const [hasHiddenBankPaymentPending, setHasHiddenBankPaymentPending] = useState<boolean>(false)
  const [isResendButtonHidden, setIsResendButtonHidden] = useState<boolean>(false)
  const [showsActivationBanner, setShowsActivationBanner] = useState(false)

  const categoryState = useSelector(categoriesSelector)
  const productState = useSelector(productsSelector)
  const userState = useSelector((state: State) => state.users)

  const { list: orders, orderIntent } = useSelector((state: State) => state.businessOrders)
  const categories = categoryState.list.slice().sort((a, b) => a.index - b.index)
  const { user, list: users } = userState
  const isUserActive = Boolean(user?.isEmailConfirmed || +new Date(user?.joinDate || 0) <= firstOfSeptember)

  const sectionCategories = (query || '').length
    ? categories
    : categories.filter(x => !user?.isUsingMenuSections || !viewType || (x.type || MenuSection.Restaurant) === viewType)

  useEffect(() => {
    dispatch(getProducts())
    dispatch(getCategories())
    dispatch(getBusinessOrders())

    if (user?.isSuperAdmin && !users.length) {
      navigate(Screen.Users)
    }

    if (!window.refreshDataInterval) {
      window.refreshDataInterval = setInterval(() => {
        if (!document.hidden) {
          dispatch(getCategories())
          dispatch(getProducts())
          dispatch(getCurrentUser())
        }
      }, REFRESH_DATA_INTERVAL)
    }

    setTimeout(() => {
      window.scrollTo({ top: 0 })
      maybeShowBusinessModal()
    }, 1500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (!isUserActive) {
        setShowsActivationBanner(true)
      }
    }, 5000)
  }, [isUserActive])

  const maybeShowBusinessModal = () => {
    const daysToExpiry = getDaysLeft(user?.subscriptionExpiresAt)
    if (isBusiness(user) && daysToExpiry < 30 && !sessionStorage['hasShownBusinessModal']) {
      window.openBusinessModal('ensureNoExpiry')
      sessionStorage['hasShownBusinessModal'] = true
    }
  }

  const orderWithPendingBankPayment = orders.find(x => x.status === BusinessOrderStatus.WaitingForBankPayment)
  const isLoading = categoryState.isLoading || productState.isLoading || userState.isLoading

  const onCategoryDragEnd = async (params: DropResult) => {
    const { destination, source, draggableId } = params

    const isCategoryReorder = source?.droppableId === 'categoriesContainer'

    if (isCategoryReorder) {
      if (!destination) {
        return
      }

      if (destination.index === source.index) {
        return
      }

      dispatch(moveCategory({ categoryId: draggableId, destinationIndex: destination.index }))
    } else {
      if (!destination) {
        return
      }

      if (destination?.droppableId !== source.droppableId) {
        const oldCategoryId = source.droppableId.replace('productsContainer', '')
        const newCategoryId = destination.droppableId.replace('productsContainer', '')

        const productName = productState.list.find(x => x.id === draggableId)?.names['ro']
        const oldCategoryName = categories.find(x => x.id === oldCategoryId)?.names['ro']
        const newCategoryName = categories.find(x => x.id === newCategoryId)?.names['ro']

        window.openCategory[newCategoryId]()

        await window.showModal({
          title: `Schimbă categorie produs`,
          text: `Ești sigur că dorești să schimbi categoria produsului "${productName}" din "${oldCategoryName}" în "${newCategoryName}"?`,
          confirmButtonText: 'Schimbă categoria',
        })

        dispatch(
          transferProduct({ productId: draggableId, destinationCategoryId: newCategoryId, destinationIndex: destination.index })
        )
      }

      if (destination.index === source.index) {
        return
      }

      dispatch(moveProduct({ productId: draggableId, destinationIndex: destination.index }))
    }
  }

  const removeProduct = async (product: MenuProduct) => {
    try {
      await window.showModal({
        title: `Șterge produs`,
        text: `Ești sigur că dorești să ștergi produsul "${product.names['ro']}"?`,
      })
      dispatch(removeProductAction(product.id))
    } catch {}
  }

  const removeCategory = async (category: MenuCategory) => {
    await window.showModal({
      title: `Șterge categoria "${category.names[LanguageCode.Romanian]}"`,
      text: `\nToate produsele din cadrul acestei categorii vor fi șterse. Ești sigur că dorești să ștergi categoria "${
        category.names[LanguageCode.Romanian]
      }"?`,
    })
    dispatch(removeCategoryAction(category.id))
  }

  const resendActivationEmail = async (email?: string) => {
    await window.showConfirm({
      title: `Retrimitere email`,
      textComponent: (
        <span>
          Dorești retrimiterea mailului de activare la adresa <b>{email}</b>?
        </span>
      ),
      confirmButtonText: 'Trimite',
    })
    await userEndpoints.resendActivationEmail()
    await window.showConfirm({
      title: `Retrimitere email`,
      textComponent: (
        <span>
          Email-ul de activare a fost retrimis cu succes la adresa <b>{email}</b>.
        </span>
      ),
    })
    setIsResendButtonHidden(true)
    setTimeout(() => {
      setIsResendButtonHidden(true)
    }, 2 * 60 * 1000) //2 minutes
  }

  const resumeOrder = () => {
    if (orderIntent?.billingInfo) {
      return navigate(Screen.EnterBillingInfo)
    }

    if (orderIntent?.shippingInfo) {
      return navigate(Screen.EnterShippingInfo)
    }

    if (
      typeof orderIntent?.plexiglassHolderCount !== 'undefined' ||
      typeof orderIntent?.laminatedHolderCount !== 'undefined' ||
      typeof orderIntent?.stickerHolderCount !== 'undefined'
    ) {
      return navigate(Screen.ChooseHolders)
    }

    return navigate(Screen.ChooseHolders)
  }

  const showCategoryModal = async () => {
    if (!isUserActive && categories.length >= 5) {
      window.showConfirm({
        title: `Funcționalitate limitată`,
        textComponent: (
          <span>
            Ai atins numărul maxim de categorii. Confirmă-ți adresa de e-mail pentru a debloca un număr nelimitat de categorii.
          </span>
        ),
      })
      return
    }
    window.openCategoryModal(null, viewType)
  }

  const changeEmail = async () => {
    const newUserEmail = window.prompt('Introdu adresa ta corectă de e-mail:')

    if (!newUserEmail) {
      return
    }

    if (!validateEmail(newUserEmail || '')) {
      return await window.showConfirm({
        title: `E-mail greșit`,
        text: ``,
        textComponent: (
          <span>
            Adresa de e-mail introdusă (<b>{newUserEmail}</b>) este invalidă. Te rog introdu o adresă de e-mail validă.
          </span>
        ),
        confirmButtonText: 'Ok',
      })
    }

    await dispatch(updateCurrentUser({ email: newUserEmail }))
    setTimeout(() => {
      resendActivationEmail(newUserEmail)
    }, 0)
  }

  const renderBanners = () => (
    <>
      {showsActivationBanner && (
        <div className="expires-banner">
          <div style={{ textAlign: 'center' }}>
            <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 8 }} />
            <span>
              Accesează linkul primit la <b>{user?.email}</b> pentru a debloca toate funcționalitățile platformei.
            </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {!isResendButtonHidden && (
              <Button
                variant="as-text"
                text="Retrimite"
                title="Retrimite mail activare"
                onClick={() => resendActivationEmail(user?.email)}
              />
            )}
            <Button variant="as-text" text="Am greșit email-ul" title="Retrimite mail activare" onClick={changeEmail} />
          </div>
        </div>
      )}
      {user?.inPartyMode && (
        <InfoPanel
          storageId="party-mode-info"
          text={`Ai intrat în modul eveniment. Acum, clienții tăi vor vedea prețurile produselor din câmpul "preț eveniment".`}
        />
      )}
      {(!user?.logoUrl || !user.coverPhotoUrl) && (
        <InfoPanel
          storageId="personalize-info"
          textComponent={
            <span>
              Bună <b>{user?.name}</b>! Accesează meniul principal pentru a-ți personaliza meniul cu logo sau poză de copertă.
            </span>
          }
        />
      )}

      {orderWithPendingBankPayment && !user?.isSuperAdmin && !hasHiddenBankPaymentPending && (
        <Panel className="centered">
          <Lottie
            {...{ ...lottieOptions, animationData: paymentPendingAnimation }}
            style={{ marginBottom: '40px', marginTop: '24px', height: 40, width: 300 }}
          />
          <p style={{ marginBottom: 0 }}>
            Comanda ta cu numărul <b>{orderWithPendingBankPayment.orderNumber}</b> va fi procesată după efectuarea plății în
            valoare de <b>{orderWithPendingBankPayment.requestedAmount} RON</b> în contul TOUCHFREEMENU SRL.
          </p>
          <p>
            Urmează instrucțiunile primite pe e-mail la adresa <b>{user?.email}</b> pentru detalii.
          </p>
          <OrderSummary
            order={{
              plexiglassHolderCount:
                orderWithPendingBankPayment.products.find(x => x.code === HolderCode.Plexiglass)?.quantity || 0,
              laminatedHolderCount:
                orderWithPendingBankPayment.products.find(x => x.code === HolderCode.Laminated)?.quantity || 0,
              stickerHolderCount: orderWithPendingBankPayment.products.find(x => x.code === HolderCode.Sticker)?.quantity || 0,
            }}
          />
          <CloseButton
            style={{ position: 'absolute', top: '4px', right: '32px' }}
            title="Închide"
            onClick={() => setHasHiddenBankPaymentPending(true)}
          />
        </Panel>
      )}

      {orderIntent && (
        <Panel className="centered">
          <div className="background-emoji">🛍️</div>
          <p>Ai o comandă neterminată.</p>

          <Button text="Continuă" onClick={resumeOrder} icon={faArrowRight} />
          <CloseButton
            style={{ position: 'absolute', top: '4px', right: '32px' }}
            title="Închide"
            onClick={() => dispatch(clearOrderIntent())}
          />
        </Panel>
      )}
      {!categories.length && !isLoading && (
        <Panel className="centered">
          <div className="background-waves">
            <img src="/misc/waves.png" />
          </div>
          <span>
            Nimic aici încă. <br />
          </span>
          <span style={{ marginTop: 16 }}>Începe prin a adăuga o categorie.</span>
          <Button style={{ marginTop: '40px' }} text="Adaugă" icon={faPlus} onClick={showCategoryModal} />
        </Panel>
      )}
    </>
  )

  const sections = SECTION_OPTIONS.filter(x => categoryState.list.find(y => (y.type || MenuSection.Restaurant) === x.value))

  return (
    <>
      <Wrapper>
        {renderBanners()}
        {Boolean(categories.length) && (
          <div className="top-bar">
            <MenuActionsRow
              onQueryChange={setQuery}
              onReorderCategoriesPress={() => setInJiggleMode(true)}
              onLanguageCodeChange={setLanguageCode}
              onCategoryAddPress={showCategoryModal}
            />

            {user?.isUsingMenuSections && (
              <SectionPicker value={viewType} options={sections} onChange={newValue => setViewType(newValue as MenuSection)} />
            )}
          </div>
        )}

        {IS_DESKTOP_OR_TABLET && user && <StatsPanel showsBanner={showsActivationBanner} />}
        {IS_DESKTOP_OR_TABLET && <MenuPreview showsBanner={showsActivationBanner} />}
        <div
          className="jiggle-mode-indicator"
          style={{ height: inJiggleMode ? '50px' : '0px', marginBottom: inJiggleMode ? '24px' : '0' }}
        >
          Editează sau reordonează categorii iar apoi fă click pe "gata".
          <ButtonStyle className="green" title="Adaugă" onClick={() => setInJiggleMode(false)} style={{ marginLeft: '8px' }}>
            Gata
          </ButtonStyle>
        </div>
        <DragDropContext onDragEnd={onCategoryDragEnd}>
          <Droppable isDropDisabled={!inJiggleMode} droppableId="categoriesContainer">
            {(provided: any) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sectionCategories.map((category, i) => (
                  <Draggable key={category.id} isDragDisabled={!inJiggleMode} draggableId={category.id} index={category.index}>
                    {(providedChildContext: any) => (
                      <div
                        ref={providedChildContext.innerRef}
                        {...providedChildContext.draggableProps}
                        {...providedChildContext.dragHandleProps}
                      >
                        <Category
                          isFirst={i === 0}
                          user={user!}
                          isUnconfirmed={!isUserActive}
                          category={category}
                          provided={providedChildContext}
                          onRemoveProductPress={removeProduct}
                          onRemoveCategoryPress={removeCategory}
                          inJiggleMode={inJiggleMode}
                          languageCode={languageCode}
                          enterJiggleMode={() => setInJiggleMode(true)}
                          query={query}
                          randomProp={+new Date()}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <CategoryInfoToast />
        <StockReminderToast />
        <ProductModal languageCode={languageCode} />
        <CategoryModal languageCode={languageCode} onRemovePress={removeCategory} />
        <PrintMenuModal />
      </Wrapper>

      {!isLoading && (
        <Footer>
          <div className="start">
            <img className="netopia-banner" src="/netopia_banner.png" alt="Netopia Visa Mastercard banner" />
          </div>
          <div className="center">
            <img className="logo" src="/logo-grayer.svg" alt="MeniuDigital Menu logo" />
          </div>
          <div className="end">
            <Link to="/terms-and-conditions">Termeni și Condiții</Link>
            <Link to="/privacy-policy">Politica de Confidențialitate</Link>
          </div>
        </Footer>
      )}
    </>
  )
}

// 30 minutes
export const REFRESH_DATA_INTERVAL = 30 * 60 * 1000

const lottieOptions = {
  loop: true,
  autoplay: window.innerWidth > 1367,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
