import React, { useEffect, useState } from 'react'
import { Modal, Header, ModalContent, ModalFooter } from './styles'
import CloseButton from 'src/global/CloseButton'
import { useSelector } from 'react-redux'
import { State } from 'src/state'
import { Backdrop } from '../ProductModal/styles'
import Button from 'src/global/Button'
import { MenuSection, LanguageCode, RomanianLanguageName } from '@meniudigital/shared'
import { getSafeUserLanguages } from '../LanguageModal'
import Flag from 'src/global/Flag'
import { userEndpoints } from 'src/api'
import { IS_DESKTOP_OR_TABLET } from '..'
import SectionPicker from '../SectionPicker'
import { SECTION_OPTIONS } from '../CategoryModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExternalLink } from '@fortawesome/free-solid-svg-icons'

export default function PrintMenuModal() {
  const { user } = useSelector((state: State) => state.users)
  const { list: categories } = useSelector((state: State) => state.categories)

  const [selectedSection, setSelectedSection] = useState<MenuSection>(MenuSection.Restaurant)
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageCode>(user?.defaultMenuLanguage || LanguageCode.Romanian)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    window.openPrintMenuModal = open
  }, [])

  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  const printMenu = () => {
    const iframe = document.querySelector('.preview iframe') as HTMLIFrameElement

    if (!IS_DESKTOP_OR_TABLET) {
      return
    }

    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)

    iframe.addEventListener(
      'load',
      () => {
        setTimeout(() => {
          iframe.contentWindow?.postMessage('printMe', process.env.REACT_APP_MENU_BASE_URL!)
          console.log('Posted printMe to ' + iframe.src)
        }, 500)
      },
      { once: true }
    )

    iframe.src = `${process.env.REACT_APP_MENU_BASE_URL}/${user?.username}?v=1&fromIframe=true&s=${selectedSection}&l=${selectedLanguage}`

    setTimeout(() => {
      userEndpoints.registerUserAction({ actionType: 'PrintMenu', data: { selectedSection, selectedLanguage } })
    }, 0)
  }

  if (!user) {
    return null
  }

  const sections = SECTION_OPTIONS.filter(x => categories.find(y => (y.type || MenuSection.Restaurant) === x.value))
  const menuUrl = `${process.env.REACT_APP_MENU_BASE_URL}/${user.username}`

  return (
    <>
      <Backdrop onClick={close} className={isOpen ? 'open' : 'closed'} />
      <Modal className={isOpen ? 'open' : 'closed'}>
        <Header>
          Printează meniul
          <CloseButton onClick={close} title="Închide" style={{ marginTop: '-18px' }} />
        </Header>
        <ModalContent>
          <div style={{ fontSize: 14, marginBottom: 16 }}>
            Pentru rezultate optime, folosește <strong>Google Chrome</strong> și setează <strong>Margins: "Default"</strong> ca
            opțiune de print.
            <br />
            <br />
            Dacă observi probleme cu încărcarea imaginilor în momentul previzualizării, accesează{' '}
            <a href={menuUrl}>
              Meniul Meu
              <FontAwesomeIcon icon={faExternalLink} />
            </a>
            , iar apoi navighează până jos și asigură-te că toate imaginile sunt încărcate corespunzător.
          </div>
          {user.isUsingMenuSections && (
            <div style={{ marginBottom: 32 }}>
              <label>Secțiune Meniu</label>
              <SectionPicker
                value={selectedSection}
                options={sections}
                stacked
                onChange={newValue => setSelectedSection(newValue as MenuSection)}
              />
            </div>
          )}
          <div className="language-selector">
            <label>Limbă</label>
            <div className="languages">
              {getSafeUserLanguages(user).map(code => (
                <div
                  key={code}
                  className={`language ${code === selectedLanguage ? 'selected' : ''}`}
                  onClick={() => setSelectedLanguage(code)}
                >
                  <Flag of={code} />
                  <div className="language-name">{RomanianLanguageName[code]}</div>
                </div>
              ))}
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            onClick={printMenu}
            text={`Printează${
              user.isUsingMenuSections ? ` ${SECTION_OPTIONS.find(x => x.value === selectedSection)?.label}` : ''
            }`}
            icon={faCheck}
            isLoading={isLoading}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}
