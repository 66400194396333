import React, { useEffect, useState } from 'react'
import Toggle from 'react-toggle'
import Button from 'src/global/Button'
import { useDispatch, useSelector } from 'react-redux'
import WaiterLoginModal from './WaiterLoginModal'

import { Wrapper } from './styles'
import { usersSelector } from 'src/state/selectors'
import { isBusiness, UserDevice, UserPatchType } from '@meniudigital/shared'
import { patchCurrentUser, removeDevice } from 'src/state/users'
import Checkbox from 'src/global/Checkbox'
import { Screen } from '../../screens'
import { Link } from 'react-router-dom'
import { userEndpoints } from 'src/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBell,
  faInfoCircle,
  faQrcode,
} from '@fortawesome/free-solid-svg-icons'
import { IS_DESKTOP_OR_TABLET } from '../HomeScreen'

const getAppUrl = `${process.env.REACT_APP_MENU_BASE_URL}/get-app`

export default function ConsumerOrders() {
  const usersState = useSelector(usersSelector)
  const user = usersState.user!
  const dispatch = useDispatch()

  const { ordering } = user
  const { isCallWaiterEnabled, isTableOrderingEnabled } = ordering || {}

  const hasEnoughDevices = (user.devices || []).filter(x => x.isPushNotificationsEnabled).length >= 2

  const isLive = Boolean(hasEnoughDevices && (isCallWaiterEnabled || isTableOrderingEnabled))
  const [checklistValues, setChecklistValues] = useState([false, false, false, false])
  const [isShowingInfos, setIsShowingInfos] = useState(true)

  useEffect(() => {
    userEndpoints.registerUserAction({ actionType: 'ViewConsumerOrders', data: {} })
  }, [])

  useEffect(() => {
    const isCurrentlyLive = Boolean(hasEnoughDevices && (isCallWaiterEnabled || isTableOrderingEnabled))
    setChecklistValues([false, false, false, false].fill(isCurrentlyLive))
    setIsShowingInfos(!isCurrentlyLive)
  }, [user])

  if (!user) {
    return null
  }

  const isEverythingChecked = checklistValues.every(Boolean)

  const isDisabled = !isEverythingChecked || !hasEnoughDevices
  const isWebDisabled = isDisabled || !ordering?.isCallWaiterEnabled

  const confirmRemoveDevice = async (device: UserDevice) => {
    await window.showModal({
      title: `Șterge dispozitiv/ospătar`,
      text: `Ești sigur că dorești să ștergi dispozitivul ${device.deviceType} aparținând ospătarului ${device.waiterName}?`,
    })
    dispatch(removeDevice(device.deviceId))
  }

  const toggleCallWaiter = async () => {
    if (!ordering?.isCallWaiterEnabled) {
      await window.showModal({
        title: `Activare funcționalitate`,
        text: `Ești sigur că dorești să activezi funcționalitatea "Cheamă ospătarul"?`,
        confirmButtonText: 'Da, activează',
        cancelButtonText: 'Nu',
      })
      dispatch(patchCurrentUser({ type: UserPatchType.OrderingEnableCallWaiter, data: true }))
    } else {
      await window.showModal({
        title: `Dezactivare funcționalitate`,
        text: `Ești sigur că dorești dezactivarea funcționalității "Cheamă ospătarul"?`,
        confirmButtonText: 'Da, dezactivează',
        cancelButtonText: 'Nu',
      })
      dispatch(patchCurrentUser({ type: UserPatchType.OrderingEnableCallWaiter, data: false }))
    }
  }

  const toggleWebCallWaiter = async () => {
    if (!isBusiness(user)) {
      window.openBusinessModal('webCallWaiter')
      return
    }

    if (!ordering?.isWebCallWaiterEnabled) {
      await window.showModal({
        title: `Activare funcționalitate`,
        text: `Funcționalitatea “Cheamă ospătarul prin browserul web” este concepută pentru a simplifica experiența clienților, fără a solicita autentificare sau permisiuni suplimentare clienților (cum ar fi locația).\n\nDin acest motiv, dar și al anonimității specifice browserelor web, autenticitatea chemărilor de ospătar nu poate fi 100% garantată.\n\nEști sigur că dorești să activezi funcționalitatea de "Cheamă Ospătarul" prin browserul web?`,
        confirmButtonText: 'Am înțeles, activează',
        cancelButtonText: 'Nu',
      })
      dispatch(patchCurrentUser({ type: UserPatchType.OrderingEnableWebCallWaiter, data: true }))
    } else {
      await window.showModal({
        title: `Dezactivare funcționalitate`,
        text: `Ești sigur că dorești dezactivarea funcționalității "Cheamă ospătarul" prin browserul web?`,
        confirmButtonText: 'Da, dezactivează',
        cancelButtonText: 'Nu',
      })
      dispatch(patchCurrentUser({ type: UserPatchType.OrderingEnableWebCallWaiter, data: false }))
    }
  }

  const toggleTableOrdering = async () => {
    if (!ordering?.isTableOrderingEnabled) {
      await window.showModal({
        title: `Activare funcționalitate`,
        text: `Ești sigur că dorești să activezi funcționalitatea de comandă la masă?`,
        confirmButtonText: 'Da, activează',
        cancelButtonText: 'Nu',
      })
      dispatch(patchCurrentUser({ type: UserPatchType.OrderingEnableTableOrdering, data: true }))
    } else {
      await window.showModal({
        title: `Dezactivare funcționalitate`,
        text: `Ești sigur că dorești să activezi funcționalitatea de comandă la masă?`,
        confirmButtonText: 'Da, dezactivează',
        cancelButtonText: 'Nu',
      })
      dispatch(patchCurrentUser({ type: UserPatchType.OrderingEnableTableOrdering, data: false }))
    }
  }

  const checkList = [
    'Doresc să folosesc funcționalitatea',
    'Am cod QR cu număr pe fiecare masă (sau identificator)',
    <span>
      <Link to={Screen.SetLocation}>Locația indicată pe hartă</Link> este corectă
    </span>,
    <span>
      Ospătarii au <a href={getAppUrl}>aplicația</a> instalată
    </span>,
  ]

  const openAddWaiter = async () => {
    window.openWaiterLoginModal?.()
  }

  const toggleCheckbox = (index: number) => {
    const newCheckboxValues = [...checklistValues]
    const newValue = !newCheckboxValues[index]
    newCheckboxValues[index] = newValue

    if (!newValue) {
      newCheckboxValues.slice(index).forEach((x, i) => (newCheckboxValues[i + index] = false))
    }
    setChecklistValues(newCheckboxValues)
  }

  const toggleInfos = () => {
    setIsShowingInfos(!isShowingInfos)
  }

  return (
    <Wrapper>
      <div className="page">
        <div className="banner">
          <span>"Cheamă ospătarul" / Comandă la masă</span>
          <div className="background-emoji">🔔</div>
          <div className="background-emoji right">🍔</div>
        </div>
        <div className={`settings ${hasEnoughDevices ? '' : 'disabled'}`}>
          <div className={`module-overview ${isShowingInfos ? '' : 'collapsed'}`}>
            <div className="header" onClick={toggleInfos}>
              <h2 className="title">Cum funcționează?</h2>
            </div>
            <div className="collapsable">
              <div style={{ display: 'flex' }}>
                <div>
                  <ul className="info-blocks">
                    <li className="info-block">
                      După vizualizarea meniului, clienții vor putea chema un ospătar sau plasa o comandă la masă prin intermediul{' '}
                      <a href={getAppUrl}>aplicației MeniuDigital</a>.
                    </li>
                    <li className="info-block">
                      Clientul va trebui să introducă numărul mesei la care se află, astfel că toate mesele tale trebuie să aibă
                      un <strong>număr vizibil care să le identifice unic</strong> (sticker cu număr lipit pe masă, număr pe
                      holderul QR, etc.)
                      {' - '}
                      <a href="https://www.google.com/search?q=identificator+numar+masa&oq=identificator+numar+masa&aqs=chrome..69i57j33i160l2.3899j0j7&sourceid=chrome&ie=UTF-8">
                        Exemple
                      </a>
                    </li>
                    <li className="info-block">
                      Dacă dorești ca numărul mesei să fie luat automat din codul QR, generează și folosește coduri QR care conțin
                      numărul mesei din secțiunea
                      <span className="qr-button" onClick={() => window.openMyQrCodeModal()}>
                        Codul meu QR <FontAwesomeIcon className="icon" icon={faQrcode} />
                      </span>
                    </li>
                    <li className="info-block">
                      În momentul în care un client cheamă un ospătar sau plasează o comandă, ospătarii tăi sunt notificați pe
                      telefon, tabletă și smartwatch prin intermediul <a href={getAppUrl}>aplicației MeniuDigital</a>.
                    </li>
                    <li className="info-block">
                      Pentru ca funcționalitatea să fie activă pentru clienți, trebuie să ai active cel puțin{' '}
                      <strong>două dispozitive</strong> mobile de ospătar (telefon/tabletă) cu notificările pornite.
                    </li>
                    <li className="info-block">
                      <span className="new-tag beta">Beta</span>
                      După activarea funcționalității "Cheamă Ospătarul prin aplicație", poți opta și pentru funcționalitatea de{' '}
                      <strong>"Cheamă Ospătarul direct din browser"</strong> (fără a fi nevoie de instalarea aplicației
                      MeniuDigital de către client).
                      <br />
                      <span className="info-explainer">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        Pentru această funcționalitate sunt obligatorii codurile QR cu numărul mesei integrat.
                      </span>
                    </li>
                  </ul>

                  <div style={{ marginTop: 24 }}>
                    {checkList.map((checklistItem, index) => (
                      <Checkbox
                        key={index}
                        text={checklistItem}
                        isChecked={checklistValues[index]}
                        onChange={() => toggleCheckbox(index)}
                        disabled={(index > 0 && !checklistValues.slice(0, index).every(Boolean)) || isLive}
                      />
                    ))}
                  </div>
                </div>
                {IS_DESKTOP_OR_TABLET && (
                  <div className="images">
                    <img src="ordering-explainers/web-menu.jpeg" alt="Ordering modal on web menu interface" />
                    <img src="ordering-explainers/table-number.jpeg" alt="Ordering process insert table number" />
                    <img src="ordering-explainers/watch.jpeg" alt="Ordering process notified on smartwatch" />
                    <img src="ordering-explainers/push-notifications.jpeg" alt="Ordering receiving push notifications" />
                  </div>
                )}
              </div>
            </div>

            {checklistValues[checklistValues.length - 1] && !hasEnoughDevices && (
              <span className="error">
                Trebuie să ai cel puțin două dispozitive de ospătar cu notificările active pentru a putea activa
                funcționalitățile.
              </span>
            )}
          </div>

          <div className={`shadow-container ${isDisabled ? 'disabled' : ''}`}>
            <div className={`setting grouped  ${isDisabled ? 'disabled' : ''}`}>
              <div className="setting-name">
                <div className="setting-icon">
                  <img src="/icons/bell.png" alt="Call Waiter icon" />
                </div>
                <span>
                  "Cheamă Ospătarul" prin aplicația <a href={getAppUrl}>MeniuDigital</a>
                </span>
                {ordering?.isCallWaiterEnabled && !isDisabled && <div className="bullet" />}
              </div>
              <div className="right-side">
                <div className="react-toggle-wrapper">
                  <span className="only-desktop">
                    {isDisabled ? 'Indisponibil' : ordering?.isCallWaiterEnabled ? 'Activ' : 'Inactiv'}
                  </span>
                  <Toggle
                    className="toggle"
                    checked={!isDisabled && ordering?.isCallWaiterEnabled}
                    icons={false}
                    onChange={toggleCallWaiter}
                  />
                </div>
              </div>
            </div>

            <div className={`setting grouped ${isDisabled ? 'disabled' : ''}`}>
              <div className="setting-name">
                <div className="setting-icon">
                  <img src="/icons/bell-order.png" alt="Order icon" />
                </div>
                <span>
                  "Comandă la Masă" prin aplicația <a href={getAppUrl}>MeniuDigital</a>
                </span>
                {ordering?.isTableOrderingEnabled && !isDisabled && <div className="bullet" />}
              </div>
              <div className="right-side">
                <div className="react-toggle-wrapper">
                  <span className="only-desktop">
                    {isDisabled ? 'Indisponibil' : ordering?.isTableOrderingEnabled ? 'Activ' : 'Inactiv'}
                  </span>
                  <Toggle
                    className="toggle"
                    checked={!isDisabled && ordering?.isTableOrderingEnabled}
                    icons={false}
                    onChange={toggleTableOrdering}
                  />
                </div>
              </div>
            </div>

            <div className={`setting grouped  ${isWebDisabled ? 'disabled' : ''}`}>
              <div className="setting-name">
                <div className="setting-icon">
                  <img src="/icons/bell.png" alt="Call Waiter icon" />
                </div>
                <span className="new-tag beta">Beta</span>
                <span>"Cheamă Ospătarul" direct din browser</span>
                {ordering?.isWebCallWaiterEnabled && !isWebDisabled && <div className="bullet" />}
              </div>
              <div className="right-side">
                <div className="react-toggle-wrapper">
                  <span className="only-desktop">
                    {isWebDisabled ? 'Indisponibil' : ordering?.isWebCallWaiterEnabled ? 'Activ' : 'Inactiv'}
                  </span>
                  <Toggle
                    className="toggle"
                    checked={!isWebDisabled && ordering?.isWebCallWaiterEnabled}
                    icons={false}
                    onChange={toggleWebCallWaiter}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="panel info" style={{ marginTop: '24px', padding: '40px 40px', textAlign: 'center' }}>
          <div className="background-waves">
            <img src="/misc/waves.png" />
          </div>

          {user?.devices.length ? (
            <>
              <span>Dispozitive/Ospătari autentificați</span>
              {user.devices.map(x => (
                <div key={x.deviceId} className="device">
                  <div
                    title={x.isPushNotificationsEnabled ? 'Notificări active' : 'Notificări oprite'}
                    className={`notifications-badge ${x.isPushNotificationsEnabled ? 'green' : 'red'}`}
                  >
                    <FontAwesomeIcon icon={faBell} />
                  </div>
                  <div className="device-info">
                    <span className="waiter-name">
                      {x.waiterName}{' '}
                      {!x.isPushNotificationsEnabled ? <span className="notifications-off"> (notificări oprite)</span> : ''}
                    </span>
                    <div className="device-type">
                      <img
                        src={`icons/${
                          x.deviceType.toLowerCase().includes('iphone') || x.deviceType.toLowerCase().includes('apple')
                            ? 'apple'
                            : 'android'
                        }.png`}
                      />
                      {x.deviceType}
                    </div>
                  </div>
                  <Button variant="as-text-danger" text="Șterge" onClick={() => confirmRemoveDevice(x)} />
                </div>
              ))}
            </>
          ) : (
            <>
              <p style={{ fontSize: 20, fontWeight: 500 }}>Nu ai niciun dispozitiv adăugat.</p>
              <p style={{ margin: '8px 0' }}>Poți adăuga primul dispozitiv de ospătar făcând click aici:</p>
            </>
          )}
          <Button text="Adaugă ospătar" icon={faQrcode} onClick={openAddWaiter} />
        </div>
      </div>
      <WaiterLoginModal />
    </Wrapper>
  )
}
