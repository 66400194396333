import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Wrapper } from './styles'
import { setLogo, setCoverPhoto, getCurrentUser } from 'src/state/users'
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import wait from 'src/helpers/wait'
import Button from 'src/global/Button'
import { State } from 'src/state'
import { Screen } from 'src/screens'
import compressImage from 'src/helpers/compressImage'
import CropImageModal from './CropImageModal'
import { IS_DESKTOP_OR_TABLET } from '../HomeScreen'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const iframeDomain = process.env.REACT_APP_MENU_BASE_URL

export enum PickedImageType {
  Logo = 'logo',
  CoverPhoto = 'coverPhoto',
}

export default function Personalize() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector((state: State) => state.users)
  const [isZoomedIn, setIsZoomedIn] = useState(false)
  const [iframeVersion, setIframeVersion] = useState(1)
  const logoUploadInput = useRef<HTMLInputElement>(null)
  const coverPhotoUploadInput = useRef<HTMLInputElement>(null)

  const { username, logoUrl = '', coverPhotoUrl = '' } = user!

  const setPickedImage = async (e: React.ChangeEvent<HTMLInputElement>, type: PickedImageType) => {
    const originalImage = e?.currentTarget?.files?.[0]

    if (originalImage) {
      const validExtensions = ['.png', '.jpg', '.jpeg']

      if (!validExtensions.find(extension => originalImage.name.toLowerCase().includes(extension))) {
        alert('Te rog alege o imagine de tipul .png sau .jpg.')
        return
      }

      window.openCropImageModal(originalImage, type)
    }
  }

  const compressAndUploadImage = async (croppedImage: File, type: PickedImageType) => {
    const compressedImage = await compressImage(croppedImage)
    const formData = new FormData()
    formData.append(type, compressedImage as Blob, compressedImage.name || '')

    if (IS_DESKTOP_OR_TABLET) {
      setIsZoomedIn(true)
    }

    if (type === PickedImageType.Logo) {
      await dispatch(setLogo(formData))
    } else {
      await dispatch(setCoverPhoto(formData))
    }

    refreshIframe()

    if (IS_DESKTOP_OR_TABLET) {
      await wait(1700)
      setIsZoomedIn(false)
      await wait(500)
    }

    dispatch(getCurrentUser())
  }

  const refreshIframe = () => {
    setIframeVersion(+new Date())
  }

  const isInFlow = new URLSearchParams(window.location.search).get('inFlow')

  return (
    <Wrapper>
      <div className="page">
        {isInFlow && (
          <span className="flow-explainer">
            <FontAwesomeIcon icon={faInfoCircle} />
            Pentru a plasa o comandă, asigură-te că adresa ta de e-mail este confirmată.
          </span>
        )}
        <h3>Ce logo și poză de fundal dorești să folosești?</h3>
        <div className="row">
          <div className="no-user-select">
            <Button
              className="select-image-button"
              text="Alege logo..."
              onClick={() => {
                logoUploadInput.current!.value = ''
                logoUploadInput?.current?.click()
              }}
              icon={logoUrl.length > 0 && faCheck}
            />
          </div>
          <div className="no-user-select">
            <Button
              className="select-image-button"
              text="Alege fundal..."
              onClick={() => {
                coverPhotoUploadInput.current!.value = ''
                coverPhotoUploadInput?.current?.click()
              }}
              icon={coverPhotoUrl.length > 0 && faCheck}
            />
          </div>
        </div>

        <div className={'preview ' + (isZoomedIn ? 'zoomed-in' : '')}>
          <div className="iframe-wrapper">
            <img className="notch-area" src="/notch_area.jpeg" alt="iPhone 13 notch area" />
            <iframe src={`${iframeDomain}/${username}?v=${iframeVersion}&fromIframe=true`} title="web-menu" />
          </div>
        </div>
        <img className={'iphone-outline ' + (isZoomedIn ? 'zoomed-in' : '')} src="/iphone_13.png" alt="iPhone 13 oultine" />

        <div className="input-wrapper">
          <input
            type="file"
            accept="image/jpeg,image/png,image/gif"
            ref={logoUploadInput}
            onChange={e => setPickedImage(e, PickedImageType.Logo)}
          />
        </div>
        <div className="input-wrapper">
          <input
            type="file"
            accept="image/jpeg,image/png,image/gif"
            ref={coverPhotoUploadInput}
            onChange={e => setPickedImage(e, PickedImageType.CoverPhoto)}
          />
        </div>
        <Button className="continue-button" text="Gata" icon={faCheck} onClick={() => navigate(Screen.Home)} />
      </div>
      <CropImageModal onCropCommit={compressAndUploadImage} />
    </Wrapper>
  )
}
