import React, { useEffect, useState } from 'react'
import { Modal, Header, ModalContent, ModalFooter } from './styles'
import Button from 'src/global/Button'
import CloseButton from 'src/global/CloseButton'
import { useDispatch, useSelector } from 'react-redux'
import { LanguageCode, RomanianLanguageName, User, UserPatchType } from '@meniudigital/shared'
import { State } from 'src/state'
import { getCategories } from 'src/state/categories'
import { getProducts } from 'src/state/products'
import { patchCurrentUser, setMenuLanguages } from 'src/state/users'
import Flag from 'src/global/Flag'
import { Backdrop } from '../ProductModal/styles'
import { isBusiness } from '@meniudigital/shared'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export function getSafeUserLanguages(user?: User) {
  if (!user) {
    return []
  }
  const unsortedSafeLanguages = user?.menuLanguages?.length ? user.menuLanguages : [LanguageCode.Romanian, LanguageCode.English]
  const safeUserLanguages = [...unsortedSafeLanguages].sort((a, b) => getLanguageCodeIndex(a) - getLanguageCodeIndex(b))
  return safeUserLanguages
}

export default function LanguageModal() {
  const dispatch = useDispatch()

  const { user } = useSelector((state: State) => state.users)
  const safeUserLanguages = getSafeUserLanguages(user)

  const [isOpen, setIsOpen] = useState(false)
  const [defaultLanguage, setDefaultLanguage] = useState<LanguageCode>(user?.defaultMenuLanguage || LanguageCode.Romanian)

  const [selectedLanguages, setSelectedLanguages] = useState<LanguageCode[]>(safeUserLanguages)
  const fixedLanguages = [LanguageCode.Romanian, LanguageCode.English]

  useEffect(() => {
    window['openLanguageModal'] = open
  }, [])

  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
    setSelectedLanguages([...safeUserLanguages])
  }

  const toggleLanguage = (code: LanguageCode) => {
    if (selectedLanguages.includes(code)) {
      setSelectedLanguages([...selectedLanguages].filter(x => x !== code))
      if (defaultLanguage === code) {
        setDefaultLanguage(LanguageCode.Romanian)
      }
    } else {
      if (selectedLanguages.length > 4) {
        return
      }
      setSelectedLanguages([...selectedLanguages, code])
    }
  }

  const save = async () => {
    setIsOpen(false)
    await dispatch(setMenuLanguages(selectedLanguages))
    await dispatch(patchCurrentUser({ type: UserPatchType.DefaultMenuLanguage, data: defaultLanguage }))
    setTimeout(() => {
      dispatch(getCategories())
      dispatch(getProducts())
    }, 500)
    setTimeout(() => {
      dispatch(getCategories())
      dispatch(getProducts())
    }, 5500)
  }

  return (
    <>
      <Backdrop className={isOpen ? 'open' : 'closed'} />
      <Modal className={isOpen ? 'open' : 'closed'}>
        <Header>
          Alege limbi meniu
          <CloseButton onClick={close} title="Închide" style={{ marginTop: '-18px' }} />
        </Header>
        <ModalContent>
          <div className="title">În ce limbi dorești să oferi meniul tău clienților?</div>
          {isBusiness(user) && (
            <ul className="subtitle-explainer">
              <li>Alege maxim 5 limbi (română +4 limbi străine)</li>
              <li>Selectează doar limbile necesare pentru o performanță optimă</li>
              <li>Adaugă limbi suplimentare doar după ce meniul este complet</li>
            </ul>
          )}

          <div className="languages">
            <label>Limbi incluse implicit</label>
            {(Object.keys(RomanianLanguageName) as LanguageCode[]).map(code => (
              <React.Fragment key={code}>
                <div
                  className={`language ${selectedLanguages.includes(code) ? 'selected' : ''} ${
                    fixedLanguages.includes(code) ? 'implicit' : ''
                  } ${!isBusiness(user) ? 'disabled' : ''}`}
                  onClick={() => toggleLanguage(code)}
                >
                  <Flag of={code} />
                  <div className="language-name">{RomanianLanguageName[code]}</div>
                  <FontAwesomeIcon icon={faCheck} style={{ opacity: selectedLanguages.includes(code) ? 1 : 0 }} />
                </div>
                {code === LanguageCode.English && (
                  <>
                    <label>Limbi extra</label>
                    {!isBusiness(user) && (
                      <div className="business-ad">
                        Adaugă limbi extra cu pachetul MeniuDigital Business™.{' '}
                        <a
                          onClick={() => {
                            close()
                            window.openBusinessModal('languages')
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          Află mai multe
                        </a>
                      </div>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
          </div>

          <div className="default-language-selector">
            <div className="title">Ce limbă dorești să fie afișată implicit la deschiderea meniului?</div>
            <div className="languages">
              {selectedLanguages.map(code => (
                <div
                  key={code}
                  className={`language ${code === defaultLanguage ? 'selected' : ''}`}
                  onClick={() => setDefaultLanguage(code)}
                >
                  <Flag of={code} />
                  <div className="language-name">{RomanianLanguageName[code]}</div>
                </div>
              ))}
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            disabled={
              safeUserLanguages.every(x => selectedLanguages.includes(x)) &&
              selectedLanguages.every(x => safeUserLanguages.includes(x)) &&
              defaultLanguage === (user?.defaultMenuLanguage || LanguageCode.Romanian)
            }
            onClick={save}
            text="Salvează"
            icon={faCheck}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}

function getLanguageCodeIndex(code: LanguageCode) {
  return Object.values(LanguageCode).indexOf(code)
}
