import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: 0 4px 12px ${props => props.theme.shadow};
  background: ${props => props.theme.white};
  border-radius: 8px;
  padding: 10px 12px;
  margin: 0 8px;
  border: 1px solid ${props => props.theme.border};
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  font-weight: 500;
  color: ${props => props.theme.normalText};

  .product-info {
    display: flex;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .product-variant {
    padding-left: 8px;
    font-size: 13px;
  }

  .info-line {
    font-size: 13px;
    margin-top: 6px;
    font-weight: 400;
    color: ${props => props.theme.normalText};

    span {
      word-break: break-word;
    }
  }

  .info-line-name {
    font-size: 13px;
    margin-right: 4px;
    font-weight: 700;
  }

  .obsolete {
    text-decoration: line-through;
  }

  .discounted {
    color: ${props => props.theme.red};
    font-weight: 600;
    display: inline-block;
    margin-left: 4px;
  }

  .hidden-icon {
    color: ${props => props.theme.red};
    width: 16px;
    margin-top: 2px;
    margin-right: 4px;
    transform: translateY(0px) ;
  }

  .during-event {
    color: ${props => props.theme.accent};
    font-weight: 600;
    display: inline-block;
    margin-left: 4px;
  }
`

export const SmallDescription = styled.div`
  font-size: 13px;
  margin-top: 6px;
  color: ${props => props.theme.normalText};
`

export const ProductImage = styled.img`
  height: 100px;

  @media (max-width: 1024px) {
    height: 55px;
  }
`

export const ProductImageWrapper = styled.div`
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 12px;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    display: flex;
    height: 55px;

    img {
      border-radius: 8px;
      margin-right: 8px;
    }
  }
`

export const OutOfStockIndicator = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 800;
  display: inline-block;
  color: ${props => props.theme.white};
  background: #ff9800;
  padding: 4px 8px;
  border-radius: 23px;
  margin-top: 2px;
  margin-right: 8px;
`

export const Button = styled.div`
  color: ${props => props.theme.blue};
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.15s ease-in-out;
  background: ${props => props.theme.blue}22;
  ${props => props.disabled && 'opacity: 0.4; pointer-events: none'};
  height: 36px;
  font-weight: 600;

  span {
    display: inline-block;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    margin-bottom: -4px;
    width: 0;
  }

  :hover {
    background: ${props => props.theme.blue}44;
  }

  &.destructive {
    color: ${props => props.theme.red};
    background: ${props => props.theme.red}22;

    :hover {
      background: ${props => props.theme.red}44;
    }
  }

  &.clone-button {
    color: ${props => props.theme.teal};
    background: ${props => props.theme.teal}22;

    :hover {
      background: ${props => props.theme.green}44!important;
    }
  }

  &.left {
    margin-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &.right {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  margin: -12px 0;
  margin-left: 12px;
  margin-right: -12px;
  padding: 16px;
  padding-bottom: 2px;
  padding-right: 0;
  background: ${props => props.theme.activeBackground};
  cursor: default;

  div {
    display: inline-block;
    margin-left: 0;
    margin-right: 12px;
    margin-bottom: 12px;
  }

  @media (min-width: 768px) {
    &.with-text-buttons {
      div {
        align-self: flex-end;
      }

      span {
        width: auto;
        margin-left: 4px;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column !important;
  }
`

export const EditButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  background: ${props => props.theme.white};
  color: ${props => props.theme.accent};
  border: 1px solid ${props => props.theme.accent};

  border-radius: 8px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background: ${props => props.theme.accent};
    color: ${props => props.theme.white};
  }
`
export const MatchedString = styled.span`
  color: ${props => props.theme.accent};
`
