import React, { useEffect, useState } from 'react'
import { Modal, Header, ModalContent, ModalFooter } from './styles'
import CloseButton from 'src/global/CloseButton'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { Backdrop } from '../../HomeScreen/ProductModal/styles'
import Button from 'src/global/Button'
import { getCurrentUser } from 'src/state/users'
import { userEndpoints } from 'src/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faKey } from '@fortawesome/free-solid-svg-icons'

export default function WaiterLoginModal() {
  const { user } = useSelector((state: State) => state.users)
  const qrCodeUrl = `${process.env.REACT_APP_MENU_BASE_URL}/${user?.username || ''}/my-login-qr-code.svg`
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)
  const [imageSrc, setImageSrc] = useState('')

  useEffect(() => {
    window['openWaiterLoginModal'] = open
    setTimeout(() => {
      getQrCodeImage()
    }, 1000)
  }, [])

  const open = () => {
    userEndpoints.registerUserAction({ actionType: 'OpenWaiterLoginModal', data: {} })
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
    setTimeout(() => {
      dispatch(getCurrentUser())
    }, 0)
  }

  const getQrCodeImage = async () => {
    const blob = await fetch(qrCodeUrl, { headers: { Authorization: `Bearer ${localStorage.token}` } }).then(r => r.blob())

    const objectUrl = window.URL.createObjectURL(blob)

    setImageSrc(objectUrl)
  }

  return (
    <>
      <Backdrop onClick={close} className={isOpen ? 'open' : 'closed'} />
      <Modal className={isOpen ? 'open' : 'closed'}>
        <Header>
          Login/Adaugă ospătar
          <CloseButton onClick={close} title="Închide" style={{ marginTop: '-18px' }} />
        </Header>
        <ModalContent>
          <div className="paragraph">
            Scanează acest cod QR cu aplicația{' '}
            <div className="app">
              <img className="app-icon" alt="MeniuDigital App Icon" src="/logo.svg" />
              <span className="app-name">MeniuDigital</span>
            </div>{' '}
            pentru a te autentifica ca ospătar al acestui restaurant:
          </div>
          <div className="qr-code-wrapper" style={{ borderColor: '#ff8922', color: '#ff8922' }}>
            <img className="qr-code" src={imageSrc} />
            <FontAwesomeIcon icon={faKey} className="center-icon" />
          </div>
          <div className="paragraph" style={{ marginTop: '24px' }}>
            Nu ai încă aplicația? Scanează acest cod QR cu{' '}
            <div className="app" style={{ fontWeight: '400' }}>
              <img
                className="app-icon"
                style={{ padding: 0, boxShadow: 'none', width: '24px', height: '24px' }}
                alt="MeniuDigital App Icon"
                src="https://i.pinimg.com/originals/e2/bc/2b/e2bc2b005d593253f62a4727d3da5d4f.png"
              />
              camera telefonului
            </div>{' '}
            pentru a o descărca:
          </div>
          <div className="qr-code-wrapper">
            <img
              style={{ width: '130px', borderRadius: '6px' }}
              className="qr-code"
              src={`${process.env.REACT_APP_MENU_BASE_URL}/get-app-qr-code.svg`}
            />
            <img src="/logo.svg" alt="MeniuDigital Logo" className="center-icon" />
          </div>
        </ModalContent>
        <ModalFooter>
          <Button onClick={close} text="Gata" icon={faCheck} />
        </ModalFooter>
      </Modal>
    </>
  )
}
