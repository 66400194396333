import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  overflow: hidden;

  .preview {
    margin-top: 24px;
    margin-bottom: 40px;
    overflow: hidden;
    width: 350px;
    height: 657px;
    position: relative;
    perspective: 3127px;
    perspective-origin: bottom;
    transition: transform 0.5s ease-in-out;

    &.zoomed-in {
      transform: scale(1.7) translateY(133px) translateX(7px);
    }
  }

  .iphone-outline {
    margin-top: 24px;
    width: 560px;
    z-index: 10;
    pointer-events: none;
    margin-top: -773px;
    margin-left: -27px;
    display: inline-block;
    transition: transform 0.5s ease-in-out;

    &.zoomed-in {
      transform: scale(1.7) translateY(133px);
    }
  }

  .iframe-wrapper {
    height: 90%;
    width: 76.6%;
    overflow: hidden;
    transform: scale(1.1) rotateX(18deg) rotateY(31deg) rotateZ(-7deg) translateX(1px) translateY(0px) translateZ(0) skewX(0deg)
      skewY(0deg);
    border-top-right-radius: 28px;
    border-top-left-radius: 39px;
    border-bottom-right-radius: 39px;
    padding-left: calc(var(--iphone-height) * 0.07);
    position: relative;
    left: 51px;
    top: 31px;
    transform-style: preserve-3d;
    background: #fff;
  }

  .notch-area {
    width: 100%;
    margin-bottom: -5px;
    border-top-right-radius: 40px;
    border-top-left-radius: 30px;
  }

  iframe {
    height: 1000px;
    width: 100%;
    border: none;
  }

  .select-image-button {
    margin: 0 16px;
    margin-bottom: 24px;
    padding: 0 16px;
    width: 160px;

    &[data-variant='as-text'] {
      width: auto;
    }
  }

  button.continue-button {
    @media (max-height: 1100px) {
      position: fixed;
      bottom: 16px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 250;
      width: 150px;
      justify-content: center;
      box-shadow: 0 0px 4px #454f5b44;
    }
  }

  .input-wrapper {
    height: 0;
    width: 0;
    overflow: hidden;
  }

  .no-user-select {
    user-select: none;
    position: relative;
  }

  p.info {
    font-size: 14px;
    margin-top: -16px;
    margin-bottom: 16px;
    width: 450px;
    max-width: 90vw;
    text-align: center;

    .icon {
      margin-right: 5px;
      color: ${props => props.theme.accent};
    }
  }

  .zoom-icon {
    position: absolute;
    bottom: 2px;
    right: -14px;
    font-size: 22px;
    color: ${props => props.theme.accent};
    opacity: 0.85;

    &[disabled] {
      color: ${props => props.theme.disabledButton};
    }
  }

  .flow-explainer {
    max-width: 500px;
    text-align: center;

    svg {
      color: ${props => props.theme.accent};
      margin-right: 4px;
    }
  }
`
