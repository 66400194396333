import styled from 'styled-components/macro'

export const Modal = styled.div`
  position: fixed;
  z-index: 100;
  top: 12vh;
  left: 0;
  right: 0;
  margin: auto;
  width: 600px;
  background: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.border};
  transition: opacity 0.25s ease-in-out;
  color: ${props => props.theme.normalText};

  border-radius: 8px;
  box-shadow: 0 4px 42px ${props => props.theme.shadow};
  overflow: hidden;

  &.closed {
    pointer-events: none;
    opacity: 0;
  }

  @media (max-width: 768px) {
    width: min(1024px, calc(100vw - 32px)) !important;
  }
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;

  width: 100%;
  background: ${props => props.theme.pageBackground};
`

export const ModalContent = styled.div`
  padding: 24px;
  position: relative;
  text-align: center;
  overflow: auto;
  padding-bottom: 24px;
  max-height: calc(100vh - 310px);

  @media (max-width: 768px) {
    padding-top: 16px;
  }

  .title {
    font-size: 22px;
    font-weight: 500;
    text-align: left;
  }

  .subtitle-explainer {
    color: ${props => props.theme.mutedText};
    padding: 8px 16px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    list-style-position: inside;
    text-align: left;
    margin-bottom: 16px;

    .fa {
      margin-right: 6px;
    }
  }

  label {
    display: block;
    width: 100%;
    text-align: left;
    margin-bottom: -8px;
  }

  .business-ad {
    text-align: left;
    font-size: 14px;
  }

  .languages {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 8px;

    @media (max-width: 768px) {
      gap: 12px;
      margin-top: 24px;
    }

    .language {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      padding-right: 24px;
      border-radius: 8px;
      box-shadow: 0 0px 12px ${props => props.theme.mutedText}44;
      cursor: pointer;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      border: 1px solid ${props => props.theme.white};
      transition: all 0.2s cubic-bezier(0.25, 1, 0.32, 1);
      user-select: none;

      @media (max-width: 768px) {
        padding: 8px;
        padding-right: 16px;
      }

      :hover:not(.implicit):not(.selected) {
        box-shadow: 0 0px 12px ${props => props.theme.mutedText}aa!important;
      }
      :active:not(.implicit) {
        transform: scale(0.9);
      }

      &.selected:not(.implicit) {
        background: ${props => props.theme.accent}dd;
        color: ${props => props.theme.white};
        box-shadow: none;
        border: 1px solid ${props => props.theme.accent};

        .bullet {
          background: ${props => props.theme.white};
          animation-name: pulse-white;
        }
      }

      svg {
        font-size: 55px;
        margin-left: 8px;
        position: absolute;
        bottom: -18px;
        right: 2px;
        z-index: 0;
        color: ${props => props.theme.white};
        transition: all 1s ease;
      }

      &.implicit {
        pointer-events: none;
        background: ${props => props.theme.white};
        box-shadow: none;
        border: 1px solid ${props => props.theme.border};

        svg {
          color: ${props => props.theme.blue};
        }
      }

      &.disabled:not(.implicit) {
        pointer-events: none;
        opacity: 0.6;
      }

      .bullet {
        margin-left: 6px;
        position: absolute;
        right: 8px;
        top: 8px;
      }

      .language-name {
        font-weight: 500;
        text-transform: capitalize;
        margin-left: 12px;
        position: relative;
        z-index: 2;
      }
    }

    .filler-div {
      width: 180px;

      @media (max-width: 756px) {
        display: none;
      }
    }

    .separator-wrapper {
      width: 100%;

      .separator {
        width: 100%;
        margin: 8px 0;
        border-top: 1px solid ${props => props.theme.pageBackground};
      }
    }
  }

  .default-language-selector {
    margin-top: 40px;

    .title {
      font-size: 16px;
    }

    .languages {
      margin-top: 16px;

      .language {
        padding: 8px 12px;

        &.selected {
          border: 1px solid ${props => props.theme.orange};
          background: ${props => props.theme.orange};
        }
      }
    }
  }
`

export const ModalText = styled.div`
  margin-top: 24px;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  font-weight: 400;
`

export const ModalFooter = styled.div`
  border-top: 1px solid ${props => props.theme.border};
  padding: 12px;
  background: ${props => props.theme.pageBackground};
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    margin-top: 0;
  }
`
