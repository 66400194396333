import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Modal, Header, ModalContent } from './styles'
import CloseButton from 'src/global/CloseButton'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { Backdrop } from '../ProductModal/styles'
import Button from 'src/global/Button'
import { getCurrentUser } from 'src/state/users'
import IosToggle from '../IosToggle'
import { faDownload, faExternalLink, faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Toggle from 'react-toggle'
import { debounce } from 'throttle-debounce'

export default function MyQrCodeModal() {
  const { user } = useSelector((state: State) => state.users)

  const dispatch = useDispatch()
  const [qrCodeFormat, setQrCodeFormat] = useState('png')
  const [withTableNumber, setWithTableNumber] = useState(false)
  const [tableNumberForImage, setTableNumberForImage] = useState<string>()
  const [tableNumber, setTableNumber] = useState<string>('')
  const [qrCodePngUrl, setQrCodePngUrl] = useState<string>()
  const [isOpen, setIsOpen] = useState(false)
  const svgRef = useRef<HTMLImageElement>(null)

  const qrCodeSvgUrl =
    withTableNumber && tableNumber
      ? `${process.env.REACT_APP_MENU_BASE_URL}/${user?.username}/my-qr-code.svg?tableNumber=${tableNumber}`
      : `${process.env.REACT_APP_MENU_BASE_URL}/${user?.username}/my-qr-code.svg`

  const onTableNumberChangeDebounced = useCallback(debounce(300, setTableNumberForImage), [])

  const setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = e.target.value
      .trim()
      .replace(/[^0-9A-Za-z]/g, '')
      .slice(0, 3)
      .toUpperCase()

    setTableNumber(sanitizedValue)
    onTableNumberChangeDebounced(sanitizedValue)
  }

  useEffect(() => {
    window.openMyQrCodeModal = open
    generatePngUrl()
  }, [qrCodeFormat, tableNumber, withTableNumber])

  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    dispatch(getCurrentUser())
    setIsOpen(false)
  }

  const openQrCode = () => {
    window.open(qrCodeFormat === 'svg' ? qrCodeSvgUrl : qrCodePngUrl, '_blank')
  }

  const generatePngUrl = async () => {
    const pngUrl = await getPngUrl()
    setQrCodePngUrl(pngUrl)
  }

  const getPngUrl = () =>
    new Promise<string>((resolve, _) => {
      const canvas = document.createElement('canvas')
      canvas.width = 400
      canvas.height = 400
      const ctx = canvas.getContext('2d')
      const img = new Image()
      img.crossOrigin = 'anonymous'
      img.onload = () => {
        ctx!.drawImage(img, 0, 0)
        canvas.toBlob(blob => {
          const link = document.createElement('a')
          link.download =
            withTableNumber && tableNumber ? `qr-${user?.username}-masa-${tableNumber}.png` : `qr-${user?.username}.png`
          const dataUrl = URL.createObjectURL(blob as Blob)
          resolve(dataUrl)
        })
      }
      img.src = qrCodeSvgUrl
    })

  if (!user) {
    return null
  }

  return (
    <>
      <Backdrop onClick={close} className={isOpen ? 'open' : 'closed'} />
      <Modal className={isOpen ? 'open' : 'closed'}>
        <Header>
          Codul meu QR
          <CloseButton onClick={close} title="Închide" style={{ marginTop: '-18px' }} />
        </Header>
        <ModalContent>
          <div className="centered-content">
            <div className="qr-code-wrapper">
              <img
                ref={svgRef}
                className="qr-code"
                src={addParamToUrl(qrCodeSvgUrl, 'tableNumber', withTableNumber ? tableNumberForImage : undefined)}
              />
            </div>
            <div className="url">
              <FontAwesomeIcon icon={faLink} />
              <span>
                {process.env.REACT_APP_MENU_BASE_URL}/<span className="slug">{user.username}</span>
              </span>
            </div>
            <div className="table-number">
              <div className="row">
                <div className="react-toggle-wrapper">
                  <Toggle
                    checked={withTableNumber}
                    title="Include număr masă în codul QR"
                    icons={false}
                    onChange={() => setWithTableNumber(!withTableNumber)}
                  />
                </div>
                <span>Include număr masă în codul QR</span>
              </div>
              <div className="input-wrapper" style={{ height: withTableNumber ? 50 : 0 }}>
                <input type="text" placeholder="Număr masă" maxLength={3} onChange={setValue} value={tableNumber} />
              </div>
              {withTableNumber && (
                <span className="explanation">
                  Numărul mesei va fi <strong>integrat</strong> în codul QR și va fi preluat automat de către aplicație. Cifrele
                  din chenar sunt doar pentru identificare vizuală.
                </span>
              )}
            </div>

            <IosToggle
              value={qrCodeFormat}
              values={[
                { value: 'svg', label: 'Vectorial (SVG)' },
                { value: 'png', label: 'Imagine (PNG)' },
              ]}
              withIcon
              onChange={newValue => setQrCodeFormat(newValue as 'svg' | 'png')}
            />
            <Button
              text={`Descarcă ${qrCodeFormat.toUpperCase()}${
                withTableNumber && tableNumber ? ` pentru masa ${tableNumber}` : ''
              }`}
              icon={faDownload}
              style={{ marginTop: 24 }}
              downloadUrl={qrCodeFormat === 'svg' ? addParamToUrl(qrCodeSvgUrl, 'shouldDownload', 'true') : qrCodePngUrl}
              downloadName={
                withTableNumber && tableNumber
                  ? `qr-${user.username}-masa-${tableNumber}.${qrCodeFormat}`
                  : `qr-${user.username}.${qrCodeFormat}`
              }
            />
            <Button
              variant="as-text"
              text={`Deschide în fereastră nouă`}
              icon={faExternalLink}
              onClick={openQrCode}
              style={{ padding: 16 }}
            />
          </div>
        </ModalContent>
      </Modal>
    </>
  )
}

function addParamToUrl(url: string, key: string, value?: string) {
  const href = new URL(url)
  if (value) {
    href.searchParams.set(key, value)
  }
  return href.toString()
}
